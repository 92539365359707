<div class="default-gradient-container">
  <app-header [isHeaderFixed]="true"></app-header>

  <div class="s-container pt-24">
    <section class="text-center py-8">
      <div class="text-[60px] font-bold text-[#101010] font-poppins leading-[78px]">Terms And Condition</div>
      <nav class="mt-2">
        <span class="text-[#101010] font-normal text-[18px] font-inter leading-[27px]">Home &gt;</span>
        <span class="font-normal bg-clip-text text-transparent bg-button-gradient text-[18px] font-inter leading-[27px]"> Terms and Conditions</span>
      </nav>
    </section>
  </div>
</div>
<div class="s-container">
  <div class="bg-[#FBDEE2] rounded-lg p-[32px] mb-10">
    <div class="p-[32px] max-w-7xl mx-auto bg-white rounded-lg shadow-lg">
      <div class="flex flex-row justify-between mb-8">
        <div class="font-inter text-[28px] font-semibold leading-[42px] text-left bg-clip-text text-transparent bg-button-gradient">
          {{effectiveDate}}
        </div>
        <div class="font-inter text-[28px] font-semibold leading-[42px] bg-clip-text text-transparent bg-button-gradient">
          {{endDate}}
        </div>
      </div>

      <!-- Policy Sections -->
      <div *ngFor="let section of termsAndConditionData" class="mb-6">
        <p class="font-poppins text-[24px] text-[#101010] text-justify font-semibold mb-4 leading-[36px]">{{ section.title }}</p>

        <!-- Description and Bullet Points -->
        <p *ngIf="section.description" class="font-poppins text-[18px] font-normal text-[#484848]" [innerHTML]="section?.description"></p>
        <ul *ngIf="section?.items" class="mt-2 font-poppins text-[18px] text-justify mb-2 leading-[27px]">
          <li *ngFor="let item of section?.items" class="flex mb-2">
            <img src="assets/images/privacy-policy/privacy-bullet.png" class="h-[9px] w-[9px] mt-[9px]" alt="" />
            <span class="ml-3"><span class="font-[500] text-[#101010]">{{ item?.title }}</span> <span class="text-[#484848] font-normal"> {{ item?.description }}</span> </span>
          </li>
        </ul>
        <ul *ngIf="section?.subItems" class="font-poppins text-[18px] text-justify mb-2 mt-4 leading-[27px]">
          <li *ngFor="let items of section?.subItems" class="flex items-start mb-8">
            <img [src]="items?.iconUrl" class="h-[30px] w-[28px]" />
            <span class="ml-3 mt-1" [innerHTML]="items?.address"></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
