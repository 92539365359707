import {Component, ViewChild} from '@angular/core';
import {DatePickerModule} from "@syncfusion/ej2-angular-calendars";
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TextBoxComponent, TextBoxModule} from "@syncfusion/ej2-angular-inputs";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-numerology-calculator',
  standalone: true,
    imports: [DatePickerModule, DropDownListModule, FormsModule, ReactiveFormsModule, TextBoxModule, CommonModule],
  templateUrl: './numerology-calculator.component.html',
  styleUrl: './numerology-calculator.component.scss'
})
export class NumerologyCalculatorComponent {
  @ViewChild('nameTextBoxRef') nameTextBoxRef!: TextBoxComponent;

  onTextBoxCreation() {
    this.nameTextBoxRef?.addIcon('append', 'e-icons e-user');
  }
}
