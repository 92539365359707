import { Component } from '@angular/core';

@Component({
  selector: 'app-get-your-astrology-report',
  standalone: true,
  imports: [],
  templateUrl: './get-your-astrology-report.component.html',
  styleUrl: './get-your-astrology-report.component.scss'
})
export class GetYourAstrologyReportComponent {

}
