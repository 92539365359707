import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {PagelayoutComponent} from "./common/component/pagelayout/pagelayout.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,PagelayoutComponent, HttpClientModule, FormsModule, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Staarvani';
}
