export const featurePublications=  [
  {
    imageUrl: 'assets/images/feature-publications/dailyhunt.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/the-guardian.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/zee-news.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/businessworld.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/dailyhunt.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/the-guardian.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/zee-news.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/businessworld.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/dailyhunt.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/the-guardian.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/zee-news.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/businessworld.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/dailyhunt.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/the-guardian.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/zee-news.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/businessworld.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/dailyhunt.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/the-guardian.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/zee-news.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/businessworld.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/dailyhunt.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/the-guardian.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/zee-news.svg'
  },
  {
    imageUrl: 'assets/images/feature-publications/businessworld.svg'
  }
];
