<section>
    <div class="s-container">
        <div class="p-12">
            <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left mb-2 text-[#101010]">
              Kundali Generator
            </div>
            <div class="font-poppins text-[18px] font-normal leading-[27px] text-left text-[#484848] mb-8">Gain a deeper
                understanding of your astrological makeup with our Kundli Generator. Enter your name and birth details
                to generate personalized charts. These charts provide valuable insights into your life’s path and the
                cosmic influences that shape it.
            </div>

            <!-- Left Column: Image -->
            <div class="flex flex-col md:flex-row items-center justify-center">

                <div class="md:w-1/2 w-full flex justify-center items-center p-6">
                    <img src="assets/home/icn_ numerlogy_logo.svg" alt="Zodiac Chart"
                        class="w-[500px] h-[500px] md:w-80 md:h-80 object-contain">
                </div>

                <!-- Right Column: Form -->
                <div class="md:w-1/2 w-full p-6 max-w-md bg-pink-50 rounded-lg shadow-kundali-generator">
                    <form class="space-y-4" [formGroup]="profileForms">
                        <!-- Name Field -->
                        <div class="flex items-center space-x-4">
                            <ejs-textbox #nameTextBoxRef placeholder="Name*" class="w-full kundali-generator-control" [floatLabelType]="'Always'" (created)="onNameTextBoxCreation()"
                                formControlName="full_name"></ejs-textbox>
                        </div>

                        <!-- Gender Field -->
                        <div class="flex items-center space-x-4">
                            <ejs-dropdownlist placeholder="Gender" class="w-full kundali-generator-control" [dataSource]="['Male', 'Female']"
                                [floatLabelType]="'Always'" formControlName="gender"></ejs-dropdownlist>
                        </div>

                        <!-- Date of Birth Field -->
                        <div class="flex items-center space-x-4">
                            <ejs-datepicker placeholder="Date of Birth*" class="w-full kundali-generator-control" [floatLabelType]="'Always'"
                                formControlName="birth_date"></ejs-datepicker>
                        </div>

                        <!-- Time of Birth -->
                        <div class="form-group birth-time-group kundali-generator-control">
                            <label class="block time-of-birth">Time of Birth</label>
                            <div class="flex items-center space-x-2">
                                <ejs-dropdownlist formControlName="hour" [dataSource]="hours" placeholder="00"
                                    class="w-full"></ejs-dropdownlist>
                                <span>:</span>
                                <ejs-dropdownlist formControlName="min" [dataSource]="minutes" placeholder="00"
                                    class="w-full"></ejs-dropdownlist>
                                <span>:</span>
                                <ejs-dropdownlist formControlName="sec" [dataSource]="seconds" placeholder="00"
                                    class="w-full"></ejs-dropdownlist>
                            </div>
                        </div>

                      <div class="flex items-center mb-4">
                        <ejs-checkbox></ejs-checkbox>
                        <label  class="ml-2 text-[#484848] text-[16px]">I don't know my exact time of birth</label>
                      </div>

                        <!-- Place of Birth Field -->
                        <div class="flex items-center space-x-4">
                            <ejs-textbox #placeOfBirthTextBoxRef formControlName="place" placeholder="Place of Birth*" class="w-full kundali-generator-control"
                                [floatLabelType]="'Always'" (created)="onPlaceOfBirthBoxCreation()"></ejs-textbox>
                        </div>

                        <!-- Submit Button -->
                        <button (click)="getKundli()" type="submit"
                            class="py-[16px] px-[32px] mt-4 bg-button-gradient text-white font-semibold rounded-lg shadow-md">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
