import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import {featurePublications} from "../../common/constants/featured-publications";

@Component({
  selector: 'app-featured-publications',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './featured-publications.component.html',
  styleUrl: './featured-publications.component.scss'
})
export class FeaturedPublicationsComponent {
  protected readonly featurePublications = featurePublications;
}
