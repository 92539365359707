import { provideRouter } from '@angular/router';
import { provideClientHydration } from '@angular/platform-browser'
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { routes } from './app.routes';
import { provideHttpClient, withFetch} from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {BrowserStorageService} from "./common/services/browser-storage.service";


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideAnimations(), provideHttpClient(withFetch()),
    importProvidersFrom(HttpClientModule), provideAnimationsAsync(), BrowserStorageService]
};
