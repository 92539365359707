<section>
  <div class="s-container">
    <div class="p-12">
      <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left mb-8 text-[#101010]">
        As Featured In
      </div>
      <div class="max-w-[1920px]">
        <div class="flex items-center">
          <div class="relative overflow-hidden inline-block">
            <div class="inline-block whitespace-nowrap relative transform translate-x-0 animate-marquee">
              <div class="inline-block whitespace-nowrap pr-[6rem]" *ngFor="let publication of featurePublications">
                <img [src]="publication.imageUrl" alt="NGK Logo" class="w-[140px] h-[80px]">
              </div>
              <div class="inline-block whitespace-nowrap pr-[6rem]" *ngFor="let publication of featurePublications">
                <img [src]="publication.imageUrl" alt="NGK Logo" class="w-[140px] h-[80px]">
              </div>
              <div class="inline-block whitespace-nowrap pr-[6rem]" *ngFor="let publication of featurePublications">
                <img [src]="publication.imageUrl" alt="NGK Logo" class="w-[140px] h-[80px]">
              </div>
              <div class="inline-block whitespace-nowrap pr-[6rem]" *ngFor="let publication of featurePublications">
                <img [src]="publication.imageUrl" alt="NGK Logo" class="w-[140px] h-[80px]">
              </div>
              <div class="inline-block whitespace-nowrap pr-[6rem]" *ngFor="let publication of featurePublications">
                <img [src]="publication.imageUrl" alt="NGK Logo" class="w-[140px] h-[80px]">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
