import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {AstroReportPromotion, Endpoint} from "../../settings/app-config";
import {BrowserStorageService} from "../../services/browser-storage.service";

@Injectable({
  providedIn: 'root'
})
export class DiscountBarService {
  private promotionDetails = new Subject<any>();
  promotionDetails$ = this.promotionDetails.asObservable();

  constructor(private http: HttpClient,  public browserStorageService: BrowserStorageService) { }

  fetchPromotionDetails(reportType: string, timezone: string) {
    let APIURL = `${Endpoint + AstroReportPromotion}`;
    if (reportType && timezone) {
      APIURL += `?report_type=${reportType}&time_zone=${timezone}`;
    }
    let authToken = this.browserStorageService.getLocalStorageKey('token');
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken,
    });
    this.http.get<any>(APIURL, { headers: headers })
      .subscribe({
        next: (data: any) => {
          if (data !== undefined && data !== null) {
            this.promotionDetails.next(data);
          }
        }
      })
  }
}
