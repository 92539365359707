import {Injectable} from '@angular/core';
import {CommunicatorService} from "../../common/services/communicator.service";
import {Endpoint} from "../../common/settings/app-config";

@Injectable({
  providedIn: 'root'
})
export class SampleReportSectionService {

  constructor(public communicatorService: CommunicatorService) {
  }

  downloadSampleReport(emailAddress: any) {
    if (emailAddress && emailAddress !== '') {
      this.communicatorService.postData(Endpoint + 'sample-report', emailAddress).subscribe({
        next: response => {
          console.log(response)
        }
      });
    }
  }
}
