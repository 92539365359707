<section>
  <div class="s-container">
    <div class="p-12">
      <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left mb-2 text-[#101010]">
        Numerology Calculator
      </div>
      <div class="font-poppins text-[18px] font-normal leading-[27px] text-left text-[#484848] mb-8">Gain a deeper
        Explore the significance of numbers in your life with our Numerology Calculator.
        Simply provide your name and date of birth to receive personalized numerological insights.
        These insights help reveal patterns and offer a fresh perspective on your journey, using the power of numerology.
      </div>
      <div class="flex items-center justify-center bg-button-gradient p-8 rounded-[20px] h-[486px]">
        <div class="mx-auto px-6 flex flex-col md:flex-row items-center justify-between">
          <div class="text-white md:w-1/2 w-full md:pr-10">
            <div class="font-poppins text-[36px] font-bold leading-[50.4px] text-left mt-2">
              Our Numerology calculator will help you get started
            </div>

            <div class="flex flex-col gap-[30px] mt-4">
              <div>
                <ejs-textbox #nameTextBoxRef placeholder="Name" class="w-full numerology-calculator-control" (created)="onTextBoxCreation()"
                             [floatLabelType]="'Always'"></ejs-textbox>
              </div>
              <div>
                <ejs-datepicker placeholder="Select Date" class="w-full numerology-calculator-control" [floatLabelType]="'Always'"></ejs-datepicker>
              </div>
              <div>
                <button class="bg-white py-[16px] text-center px-[32px] mt-4 leading-[24px] text-[20px] font-bold rounded-lg shadow-md">
                  <span class="text-transparent bg-clip-text bg-button-gradient ">Submit</span>
                </button>
              </div>
            </div>
          </div>

          <!-- Image Section -->
          <div class="md:w-1/2 w-full mt-8 md:mt-0 flex justify-center">
            <img src="/assets/home/numerology-element.png" alt="Numerology Chart"
                 class="w-[377px] h-[364px] md:w-80 md:h-80 object-contain">
          </div>
        </div>
      </div>

    </div>
  </div>

</section>
