import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-get-your-kid-astrology-report',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './get-your-kid-astrology-report.component.html',
  styleUrl: './get-your-kid-astrology-report.component.scss'
})
export class GetYourKidAstrologyReportComponent {
  cards = [
    {
      logo: '../../../../assets/home/kid-astro-f1.svg',
      title: "Child's Astrological Chart",
      description:
        "A personalized look into your child's sun, moon, and rising signs to understand their unique traits.",
    },
    {
      logo: '../../../../assets/home/kid-astro-f2.svg',
      title: 'Behavioral Insights',
      description:
        'Gain clarity on your child’s natural temperament and how to nurture their growth.',
    },
    {
      logo: '../../../../assets/home/kid-astro-f3.svg',
      title: 'Educational Guidance',
      description:
        'See how your child’s astrological traits can influence learning styles and academic potential.',
    },
    {
      logo: '../../../../assets/home/kid-astro-f4.svg',
      title: 'Health and Well-being',
      description:
        'Learn how your child’s astrological profile may impact their physical and emotional health.',
    },
    {
      logo: '../../../../assets/home/kid-astro-f5.svg',
      title: 'Parenting Tips',
      description:
        'Get personalized suggestions on how to parent according to your child’s astrological makeup for a harmonious relationship.',
    },
    {
      logo: '../../../../assets/home/kid-astro-f6.svg',
      title: 'Future Potential',
      description:
        'Understand the long-term potential of your child, including key astrological transits that may influence important life decisions.',
    },
  ];

  getCardClasses(index: number) {
    const gradientBackground = 'bg-gradient-alternate';
    const whiteBackground = 'bg-white';
    const commonStyles = `
      shadow-kid-astro-shadow p-6 rounded-lg 
      flex-grow h-[382px] w-[370px]
    `;
    const isGradient = index % 2 === 0;
    const extraMarginTop = index === 1 || index === 4 ? 'mt-4' : '';
    return `${commonStyles} ${isGradient ? gradientBackground : whiteBackground} ${extraMarginTop}`;
  }

  getGradientBorder(index: number) {
    return {
      border: '1px solid #F75D59',
      // borderImageSource: 'linear-gradient(270deg, #EF8248 0%, #E33C49 50.94%, #BE2450 100%)',
      // borderImageSlice: 1,  // This is crucial to make sure the gradient applies correctly
    };
  }
}
