import {Component, OnDestroy, OnInit} from '@angular/core';
import {TextBoxAllModule} from "@syncfusion/ej2-angular-inputs";
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SampleReportSectionService} from "./sample-report-section.service";
import {preferredEmailFormat} from "../../common/settings/app-config";

@Component({
  selector: 'app-sample-report-section',
  standalone: true,
  imports: [
    TextBoxAllModule, CommonModule, ReactiveFormsModule
  ],
  templateUrl: './sample-report-section.component.html',
  styleUrl: './sample-report-section.component.scss'
})
export class SampleReportSectionComponent implements OnInit, OnDestroy {
  public sampleEmailReportForm!: FormGroup;

  constructor(public formBuilder: FormBuilder, public sampleReportSectionService: SampleReportSectionService) {
  }
  ngOnInit() {
    this.sampleEmailReportForm = this.formBuilder.group({
      email: [null, Validators.compose([Validators.pattern(preferredEmailFormat), Validators.required])],
    });
  }

  ngOnDestroy() {
  }

  sendSampleReportOnEmail() {
    if (this.sampleEmailReportForm?.valid) {
      this.sampleReportSectionService.downloadSampleReport(this.sampleEmailReportForm?.value);
    } else {
      this.sampleEmailReportForm?.markAllAsTouched();
    }
  }
}
