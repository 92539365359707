import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CarouselAllModule } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-what-client-says-about-us',
  standalone: true,
  imports: [CommonModule, CarouselAllModule],
  templateUrl: './what-client-says-about-us.component.html',
  styleUrl: './what-client-says-about-us.component.scss'
})
export class WhatClientSaysAboutUsComponent implements OnInit {
  chunkedClients: any[] = [];
  clients = [
        {
          "name": "Haylie Gouse",
          "role": "Lorem Ipsum",
          "feedback": "Incredible insight! The numerology report provided by Staarvani was eye-opening. It perfectly aligned with my personal experiences and offered clear guidance for my future.",
          "image": "assets/home/person-1.png"
        },
        {
          "name": "John Doe",
          "role": "Software Engineer",
          "feedback": "I was amazed by the accuracy of the numerology report I received. It helped me understand myself better and make more informed decisions. Highly recommended!",
          "image": "assets/home/person-2.png"
        },
        {
          "name": "Jane Smith",
          "role": "Product Manager",
          "feedback": "The numerology report was thorough and insightful. It offered a deep understanding of my life path and potential. Staarvani has exceeded my expectations!",
          "image": "assets/home/person-3.png"
        },
        {
          "name": "Emily Clark",
          "role": "Designer",
          "feedback": "I've had numerology reports before, but Staarvani offered something truly special. The detailed analysis and practical advice were incredibly helpful.",
          "image": "assets/home/person-1.png"
        },
        {
          "name": "Michael Brown",
          "role": "Marketing",
          "feedback": "I was skeptical at first, but the numerology report from Staarvani was spot-on. It gave me valuable insights into my strengths and challenges.",
          "image": "assets/home/person-2.png"
        },
        {
          "name": "Sophia Johnson",
          "role": "Consultant",
          "feedback": "The clarity and depth of the numerology report were beyond what I anticipated. Staarvani provided a comprehensive view that really resonated with me.",
          "image": "assets/home/person-3.png"
        },
        {
          "name": "David Wilson",
          "role": "CEO",
          "feedback": "Exceptional service! The numerology report was not only accurate but also beautifully presented. It’s clear that Staarvani puts a lot of care into their work.",
          "image": "assets/home/person-1.png"
        },
        {
          "name": "Lucas Anderson",
          "role": "CFO",
          "feedback": "I've consulted numerologists before, but the report I received from Staarvani was by far the most detailed and enlightening. It’s a fantastic tool for personal growth.",
          "image": "assets/home/person-2.png"
        },
        {
          "name": "Charlotte Davis",
          "role": "HR Specialist",
          "feedback": "The numerology report was an eye-opener. It gave me a new perspective on my life and career. Thank you, Staarvani, for such a profound and helpful analysis.",
          "image": "assets/home/person-3.png"
        },
        {
          "name": "Mason Martinez",
          "role": "Data Scientist",
          "feedback": "I’m incredibly impressed with the numerology report from Staarvani. It’s clear, insightful, and has provided me with practical advice that I can apply in my daily life.",
          "image": "assets/home/person-1.png"
        },
        {
          "name": "Olivia Thomas",
          "role": "UX Researcher",
          "feedback": "The numerology report was precise and incredibly relevant. Staarvani has a real talent for understanding the nuances of numerology and applying them to real-life situations.",
          "image": "assets/home/person-2.png"
        },
        {
          "name": "Ethan Garcia",
          "role": "DevOps Engineer",
          "feedback": "I’ve never had a numerology report quite like this before. Staarvani provided detailed insights that felt both personal and empowering. Highly recommend!",
          "image": "assets/home/person-3.png"
        },
        {
          "name": "Ava White",
          "role": "Content Strategist",
          "feedback": "The numerology report I received was comprehensive and enlightening. It helped me see my life from a new perspective and offered valuable guidance for my future.",
          "image": "assets/home/person-1.png"
        },
        {
          "name": "James Harris",
          "role": "Front-End Developer",
          "feedback": "I am so pleased with the numerology report from Staarvani. It’s not only accurate but also delivered in a way that’s easy to understand and apply.",
          "image": "assets/home/person-2.png"
        },
        {
          "name": "Lily Robinson",
          "role": "Marketing Manager",
          "feedback": "Fantastic experience! The numerology report provided clear insights and actionable advice. Staarvani has a unique gift for interpreting numbers with great accuracy.",
          "image": "assets/home/person-3.png"
        },
        {
          "name": "Noah Lee",
          "role": "Business Analyst",
          "feedback": "The numerology report was detailed and insightful. Staarvani provided an in-depth analysis that helped me understand my personal and professional life better.",
          "image": "assets/home/person-1.png"
        },
        {
          "name": "Chloe Walker",
          "role": "Operations Manager",
          "feedback": "I was truly impressed with the numerology report from Staarvani. It offered a deep dive into my personal traits and future potential that was both enlightening and empowering.",
          "image": "assets/home/person-2.png"
        },
        {
          "name": "Logan Scott",
          "role": "Digital Marketer",
          "feedback": "The numerology report was a wonderful surprise. Staarvani provided detailed and accurate information that has helped me make important decisions in my life.",
          "image": "assets/home/person-3.png"
        }
      ]

  ngOnInit(): void {
    this.chunkedClients = this.chunkArray(this.clients, 3);
  }

  chunkArray(arr: any[], chunkSize: number) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  }
}
