import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public reportStyling = new Subject<string>();
  public reportStyling$ = this.reportStyling.asObservable();

  constructor() {
  }

  shareCurrentReportNameForStyling(reportName: string) {
    this.reportStyling.next(reportName);
  }
}
