import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Subscription} from "rxjs";
import {HeaderComponent} from "../../common/component/header/header.component";
import {HeaderService} from "../../common/component/header/header.service";
import {ReportCardsComponent} from "../report-cards/report-cards.component";
import {FeaturedPublicationsComponent} from "../featured-publications/featured-publications.component";
import {OurServicesComponent} from "../our-services/our-services.component";
import {WhatClientSaysAboutUsComponent} from "../what-client-says-about-us/what-client-says-about-us.component";
import {KundaliGeneratorComponent} from "../kundali-generator/kundali-generator.component";
import {NumerologyCalculatorComponent} from "../numerology-calculator/numerology-calculator.component";
import {OurGuruComponent} from "../our-guru/our-guru.component";
import {GoogleReviewsComponent} from "../google-reviews/google-reviews.component";
import {FooterComponent} from "../../common/component/footer/footer.component";

interface Review {
    reviewerName: string;
    reviewerImage: string;
    rating: number;
    reviewText: string;
    reviewDate: any;
}


@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [CommonModule, HeaderComponent, ReportCardsComponent, FeaturedPublicationsComponent, OurServicesComponent, WhatClientSaysAboutUsComponent, KundaliGeneratorComponent, NumerologyCalculatorComponent, OurGuruComponent, GoogleReviewsComponent, FooterComponent],
})


export class HomeComponent  implements OnInit, OnDestroy {
  public reportBackgroundStyle: string = 'default-gradient-container';
  public reportStyling$: Subscription = new Subscription();

  constructor(private headerService: HeaderService) {
  }

  public ngOnInit() : void{
    this.reportStyling$ = this.headerService.reportStyling$.subscribe((reportName: string) => {
      if (reportName) {
        this.reportBackgroundStyle = reportName
      }
    })
  }

  ngOnDestroy() {
    if (this.reportStyling$) {
      this.reportStyling$.unsubscribe();
    }
  }

}



