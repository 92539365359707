<div>
  <div [ngClass]="reportBackgroundStyle">
    <div [ngClass]="{'header-hidden': isSolid}">
      <app-header [isHeaderFixed]="false"></app-header>
    </div>
    <div>
      <ejs-tab #reportHeaderTabRef (selected)="onSelectingTabs($event)" [ngClass]="{'header-solid': isSolid}">
        <e-tabitems>
          <e-tabitem *ngFor="let headerDetails of reportHeader" [header]="headerDetails">
          </e-tabitem>
        </e-tabitems>
      </ejs-tab>

    </div>

    <section class="section1 pt-8">
      <div class="s-container">
        <ejs-carousel #carousel [items]="currentReport" width="100%" autoPlay="false"
                      cssClass="custom-carousel" [buttonsVisibility]="'Hidden'">
          <e-carousel-items>
            <e-carousel-item *ngFor="let card of currentReport">
              <ng-template #template>
                <div class="flex flex-col lg:flex-row items-center justify-between p-6 cursor-pointer">
                  <!-- Content on the left -->
                  <div class="flex-1 flex flex-col items-start">
                    <div class="font-poppins text-[60px] font-semibold leading-[78px] text-left text-black">
                      {{ card?.feature?.home_screen?.title }}
                    </div>
                    <p class="mt-2 font-poppins text-[20px] font-normal leading-[30px] text-left text-[#484848]">
                      {{ card?.feature?.home_screen?.subtitle }}
                    </p>
                    <button
                      class="bg-button-gradient text-white rounded-xl mt-8 flex items-center gap-x-3 text-base border py-2.5">
                    <span class="px-4">
                        {{ card.report_name  | capitalizeFirstLetter }} For {{ card.checkout_price | currency:card.currency }}
                      <del *ngIf="card.price !== card.checkout_price">{{ card.price | currency: card.currency }}</del>/- Only!
                    </span>
                    </button>
                  </div>

                  <!-- Image on the right -->
                  <div>
                    <img [src]="card?.banner_url" alt="a" class="mx-auto">
                  </div>
                </div>
              </ng-template>
            </e-carousel-item>
          </e-carousel-items>
        </ejs-carousel>

      </div>
    </section>
  </div>
  <app-featured-publications></app-featured-publications>
  <div class="bg-gradient-to-r from-[#FFFFFF] via-[#FFFBFC] to-[#FFF4F5]">
    <app-get-your-numerology-report *ngIf="reportType === 'numerology'"></app-get-your-numerology-report>
    <app-get-your-astrology-report *ngIf="reportType === 'astrology'"></app-get-your-astrology-report>
    <app-get-your-kid-astrology-report *ngIf="reportType === 'kid-astrology'"></app-get-your-kid-astrology-report>
    <app-get-your-kid-numerology-report *ngIf="reportType === 'kid-numerology'"></app-get-your-kid-numerology-report>
    <app-get-your-name-correction-report *ngIf="reportType === 'name-correction'"></app-get-your-name-correction-report>
  </div>
  <app-sample-report-section></app-sample-report-section>
  <app-why-we-are-we-different-numerology *ngIf="reportType === 'numerology'"></app-why-we-are-we-different-numerology>
  <app-why-different-report-section *ngIf="reportType === 'kid-numerology' || reportType === 'name-correction' ||
  reportType === 'astrology'"></app-why-different-report-section>
  <app-why-we-are-different-kid-astro-report-section *ngIf="reportType === 'kid-astrology'"></app-why-we-are-different-kid-astro-report-section>
  <app-faq-section></app-faq-section>
</div>
<app-discount-bar [reportType]="reportType"></app-discount-bar>



