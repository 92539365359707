export const Endpoint = "https://api.staarvani.com/app/v1/";
export const preferredEmailFormat = "^[a-zA-Z0-9]+([-+._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-.]?[a-zA-Z0-9]+)*\\.[a-zA-Z]{1,}$";
export const BabyName = "baby-names";
export const KundaliBasicDetails = "kundali/basic-details";
export const KundaliHoroscopeChart = "kundali/horoscope-chart";
export const KundaliPlanetaryPositions = "kundali/planetary-positions";
export const KundaliAscendantReport = "kundali/ascendant-report";
export const KundaliVimshottariDosha = "kundali/vimshottari-dasha";
export const KundaliDoshaReport = "kundali/dosha";
export const UpdateAgentProfile = "agent/profile";
export const AgentsOnlineStatus = "agent/online-status";
export const AstroReportPromotion = 'astro-report-promotion';
export const ZodiacSign = 'zodiac-sign';
export const DailyHoroscope = 'daily-horoscope';
export const Locations = 'constant/locations';
