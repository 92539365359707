<div class="s-container mb-10">
  <div class="flex flex-col md:flex-row items-center gap-[210px]">
    <!-- Left Section -->
    <div class="w-[370px] p-8">
      <div class="font-poppins text-[40px] font-semibold leading-[56px] text-[#101010] mb-6">FAQs</div>
      <div class="font-poppins text-[16px] font-normal leading-[27px] text-left text-[#484848] mb-6">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
      </div>
    </div>

    <!-- Right Section (FAQ Accordion) -->
    <div class="w-full md:w-1/2">
      <div *ngFor="let faq of faqs; let i = index" class="mb-4">
        <div class="bg-white rounded-lg shadow-faq-box-shadow overflow-hidden">
          <button (click)="toggleFAQ(i)" class="w-full flex justify-between items-center p-4 text-left focus:outline-none">
            <div class="font-poppins text-[20px] font-[500px] leading-[26px] text-[#101010]">{{ faq.question }}</div>
            <div class="flex items-center justify-center h-[20px] w-[20px] bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450] text-white rounded-full">
            <span *ngIf="openIndex === i">+</span>
            <span *ngIf="openIndex !== i">-</span>
          </div>
          </button>
          <div *ngIf="openIndex === i" class="p-4 pt-0 font-poppins text-[14px] font-normal leading-[21px] text-left text-[#484848]">
            {{ faq.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
