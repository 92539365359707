<section>
   <div class="s-container pt-12">
      <ejs-carousel #carousel [items]="reportCards" width="100%" interval="10000" autoPlay="true" loop="true" (slideChanging)="slideChanging($event)"
         cssClass="custom-carousel" [buttonsVisibility]="'Hidden'">
         <e-carousel-items>
            <e-carousel-item *ngFor="let card of reportCards">
               <ng-template #template>
                  <div class="flex flex-col lg:flex-row items-center justify-between p-6 cursor-pointer" (click)="navigateTo(card)">
                     <!-- Content on the left -->
                     <div class="flex-1 flex flex-col items-start">
                        <div class="font-poppins text-[60px] font-semibold leading-[78px] text-left text-black">
                          {{ card?.feature?.home_screen?.title }}
                        </div>
                        <p class="mt-2 font-poppins text-[20px] font-normal leading-[30px] text-left text-[#484848]">
                          {{ card?.feature?.home_screen?.subtitle }}
                        </p>
                        <button
                           class="new-gradient-bg text-white rounded-xl mt-8 flex items-center gap-x-3 text-base border py-2.5">
                          <span class="px-4">
                            {{ card.report_name | capitalizeFirstLetter }} For {{ card.checkout_price | currency:card.currency }}
                            <del *ngIf="card.price !== card.checkout_price">{{ card.price | currency: card.currency }}</del>/- Only!
                          </span>
                        </button>
                     </div>

                     <!-- Image on the right -->
                     <div>
                        <img [src]="card?.banner_url" alt="a" class="mx-auto">
                     </div>
                  </div>
               </ng-template>
            </e-carousel-item>
         </e-carousel-items>
      </ejs-carousel>
   </div>

</section>
