<div class="s-container mx-auto my-12 text-center p-12">
    <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-[#101010]">
        Here's Why We Are Different:
    </div>
    <div class="font-poppins text-[18px] text-[#484848] font-normal leading-[27px] mt-2">
        Our reports are unlike anything you would have read online or offline!
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-14">
        <!-- Card 1 -->
        <div class="relative ">
            <!-- L Shape image positioned behind the card -->
            <div class="absolute bottom-0 left-0 -translate-x-3.5 translate-y-3.5">
                <img src="assets/home/kid-astro-card-bottom.svg" alt="L Shape" class="w-32 h-32">
            </div>
            <div class="bg-white rounded-lg  shadow-kid-astro-shadow  p-6 relative z-10 h-[388px]">
                <div class="flex justify-center mb-4">
                    <img src="assets/home/kid-astro-why-different-1.svg" alt="Icon" class="w-12 h-12">
                </div>
                <div class="font-poppins text-xl font-semibold leading-7 text-center text-[#101010]">
                    Years of Experience in Vedic Astrology Simplified
                </div>
                <div class="font-poppins text-base font-normal leading-6 text-center text-gray-700 mt-2">
                    Purposeful interpretations that help you understand where you are in your life, what is your true
                    purpose. The science of 'Jyotish' is to illuminate our true paths and move forward with confidence!
                </div>
            </div>
        </div>

        <!-- Card 2 -->
        <div class="relative ">
            <!-- L Shape image positioned behind the card -->
            <div class="absolute bottom-0 left-0 -translate-x-3.5 translate-y-3.5">
                <img src="assets/home/kid-astro-card-bottom.svg" alt="L Shape" class="w-32 h-32">
            </div>
            <div class="bg-white rounded-lg shadow-kid-astro-shadow p-6 relative z-10 h-[388px]">
                <div class="flex justify-center mb-4">
                    <img src="assets/home/kid-astro-why-different-2.svg" alt="Icon" class="w-12 h-12">
                </div>
                <div class="font-poppins text-xl font-semibold leading-7 text-center text-[#101010]">
                    Trusted by over 75K happy customers
                </div>
                <div class="font-poppins text-base font-normal leading-6 text-center text-gray-700 mt-2">
                    We are thrilled to have had the privilege of touching the lives of our many happy customers. Your
                    support keeps us going.
                </div>
            </div>
        </div>

        <!-- Card 3 -->
        <div class="relative">
            <!-- L Shape image positioned behind the card -->
            <div class="absolute bottom-0 left-0 -translate-x-3.5 translate-y-3.5">
                <img src="assets/home/kid-astro-card-bottom.svg" alt="L Shape" class="w-32 h-32">
            </div>
            <div class="bg-white rounded-lg  shadow-kid-astro-shadow  p-6 relative z-10  h-[388px]">
                <div class="flex justify-center mb-4">
                    <img src="assets/home/kid-astro-why-different-3.svg" alt="Icon" class="w-12 h-12">
                </div>
                <div class="font-poppins text-xl font-semibold leading-7 text-center text-[#101010]">
                    No Hidden Cost
                </div>
                <div class="font-poppins text-base font-normal leading-6 text-center text-gray-700 mt-2">
                    We will never push you for astrological remedies. No stones and no poojas. Our report provides
                    important astrological guidance for your self-awareness as per the science of Vedic Astrology.
                </div>
            </div>
        </div>
    </div>
</div>
