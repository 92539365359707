import {Component} from '@angular/core';
import {CommonModule, LowerCasePipe} from "@angular/common";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    LowerCasePipe, CommonModule
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public usefulLinksSection  =      {
    title: 'Useful Links',
    links: [
      {name: 'About Us', url: '#'},
      // {name: 'Course', url: '#'},
      // {name: 'Product', url: '#'},
      // {name: 'Blog', url: '#'},
      {name: 'FAQs', url: '#'},
      // {name: 'Contact Us', url: '#'},
      {name: 'Kundali Generator', url: '#'},
      {name: 'Numerology Calculator', url: '#'}
    ]
  }

  public policySection = {
    title: 'Policies',
    links: [
      {name: 'Refund and Return Policy', url: '/refund-policy'},
      {name: 'Cancellation Policy', url: '/cancellation-policy'},
      // {name: 'Shipping and Delivery Policy', url: '#'},
      {name: 'Terms & Conditions', url: '/terms-and-condition'},
      {name: 'Privacy Policy', url: '/privacy-policy'}
    ]
  }

  public stayInTouchSection =  {
    title: 'Stay in Touch',
    contacts: [
      { type: 'email', value: 'support@staarvani.com' },
      { type: 'address', value: '48B Sports Villa, Jaypee Greens, Greater Noida 201310' },
      { type: 'phone', value: '+91 9002700221' }
    ],
    socialLinks: [
      { platform: 'LinkedIn', url: '#' },
      { platform: 'YouTube', url: '#' },
      { platform: 'Instagram', url: '#' },
      { platform: 'Facebook', url: '#' },
      { platform: 'Twitter', url: '#' }
    ]
  }
}
