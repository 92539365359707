import { CommonModule } from '@angular/common';
import {Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DatePickerAllModule, TimePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import {TextBoxAllModule, TextBoxComponent} from '@syncfusion/ej2-angular-inputs';
import { KundaliGeneratorService } from './kundali-generator.service';
import { Router } from '@angular/router';
import {CheckBoxModule} from "@syncfusion/ej2-angular-buttons";
import {BrowserStorageService} from "../../common/services/browser-storage.service";

@Component({
  selector: 'app-kundali-generator',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, TextBoxAllModule, DropDownListAllModule, TimePickerAllModule, DatePickerAllModule, CommonModule, CheckBoxModule],
  templateUrl: './kundali-generator.component.html',
  styleUrl: './kundali-generator.component.scss',
  providers: []
})
export class KundaliGeneratorComponent implements OnInit {
  profileForms!: FormGroup;
  hours = Array.from({ length: 24 }, (_, i) => ('0' + i).slice(-2));
  minutes = Array.from({ length: 60 }, (_, i) => ('0' + i).slice(-2));
  seconds = Array.from({ length: 60 }, (_, i) => ('0' + i).slice(-2));
  @ViewChild('nameTextBoxRef') nameTextBoxRef!: TextBoxComponent;
  @ViewChild('placeOfBirthTextBoxRef') placeOfBirthTextBoxRef!: TextBoxComponent;

  constructor(private fb: FormBuilder,
    private kundaliGeneratorService: KundaliGeneratorService,
    private router: Router, private browserStorageService: BrowserStorageService) { }


  ngOnInit(): void {
    this.initializeProfileForms();
  }

  getKundli() {
    const formValue = this.profileForms.value;
    const birthdate = new Date(formValue.birth_date);
    const day = birthdate.getDate().toString().padStart(2, '0');
    const month = (birthdate.getMonth() + 1).toString().padStart(2, '0');
    const year = birthdate.getFullYear();
    const finalFormValue = {
      ...formValue,
      day: day,
      month: month,
      year: year.toString(),
      lat: 11.94,
      lon: 79.80,
      gender: formValue.gender.toString().toLowerCase()
    };
    // TODO: Need to remove the dependency of local storage from here
    this.browserStorageService.setLocalStorageKey("horoscopeData", JSON.stringify(finalFormValue));
    this.router.navigate(['/kundli-details']);
  }


  initializeProfileForms(): void {
    this.profileForms = this.fb.group({
      full_name: ['', Validators.required],
      gender: [''],
      birth_date: ['', Validators.required],
      hour: ['', Validators.required],
      min: ['', Validators.required],
      sec: ['00', Validators.required],
      place: ['', Validators.required],
    });
  }

  onNameTextBoxCreation() {
    this.nameTextBoxRef?.addIcon('append', 'e-icons e-user');
  }

  onPlaceOfBirthBoxCreation() {
    this.placeOfBirthTextBoxRef?.addIcon('append', 'e-icons e-location');
  }
}
