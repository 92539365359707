<section>
  <div class="s-container">
    <div class="p-12">
      <div class="flex flex-col lg:flex-row items-center">
        <!-- Text Content -->
        <div class="lg:w-[65%] mb-8 lg:mb-0">
          <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left text-[#101010]">Our Guru
          </div>
          <div class="font-poppins text-[20px] font-normal leading-[30px] text-left text-[#101010] mt-4">
            Maniesh K Maurya Ji is a revered guru in numerology with over 12 years of experience and a global clientele exceeding 14,000 clients. His extensive expertise has established him as a trusted advisor worldwide. With a background that includes a B.Tech, an MS from BU, New York, and an Executive Programme in Business Management (EPBM) from IIM Calcutta, Maniesh Ji blends analytical precision with spiritual insight.
            His numerological practice encompasses analyzing the vibrational significance of numbers for personal and professional decisions. Maniesh Ji’s holistic approach integrates <span class="font-semibold">Astrology</span>, <span class="font-semibold">Swar Vigyaan</span>, <span class="font-semibold">Shiv Shakti</span>, <span class="font-semibold">Law of Attraction</span>, <span class="font-semibold">Vastu</span>, and <span class="font-semibold">Panchkosh</span>. He provides tailored solutions to enhance success and harmony in various aspects of life.
          </div>
        </div>

        <div class="md:w-[35%] w-full relative flex justify-center items-center mt-24 lg:mt-0">
          <img src="assets/home/guru-image.png" alt="Guru Image"
               class="relative object-cover w-[350px] h-[350px] z-10">

          <!-- Name Label -->
          <div class="absolute bottom-[15%] left-[30%] transform -translate-x-1/2 translate-y-[50%] bg-white px-2 py-2 rounded-[12px] shadow-guru-shadow z-20">
            <span class="font-poppins font-semibold text-[16px] text-[#101010]">Maniesh K Maurya</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
