import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselAllModule, CarouselComponent } from '@syncfusion/ej2-angular-navigations';
import {ReportService} from "../report/report.service";
import {Subscription} from "rxjs";
import {ReportList} from "../report/model/report";
import {CapitalizeFirstLetterPipe} from "../../common/pipes/capitalize-first-letter.pipe";
import {HeaderService} from "../../common/component/header/header.service";


@Component({
  selector: 'app-report-cards',
  standalone: true,
  imports: [CommonModule, CarouselAllModule, CapitalizeFirstLetterPipe],
  templateUrl: './report-cards.component.html',
  styleUrl: './report-cards.component.scss'
})
export class ReportCardsComponent implements OnInit {
  @ViewChild('carousel', { static: false }) carousel!: CarouselComponent;
  public reportCards: ReportList[] = [];
  public reportListDetails$ = new Subscription();

  constructor(private router: Router, public reportService: ReportService, public headerService: HeaderService) { }

  ngOnInit(): void {
    this.reportListDetails$ = this.reportService.reportListDetails$.subscribe((reportList: ReportList[]) => {
      if (reportList) {
        this.reportCards = reportList;
        const currentReportCard = this.reportCards?.[0]?.report_name + '-gradient-container';
        this.headerService.shareCurrentReportNameForStyling(currentReportCard);
      }
    })
    this.reportService.getReportListDetails();
  }

  navigateTo(cardDetails: ReportList): void {
    if (cardDetails && (cardDetails?.report_name && cardDetails?.report_name !== '') &&
      (cardDetails?.feature?.home_screen?.link && cardDetails?.feature?.home_screen?.link !== '')) {
      this.headerService.shareCurrentReportNameForStyling(cardDetails?.report_name + '-gradient-container');
      this.router.navigate([cardDetails?.feature?.home_screen?.link, cardDetails?.report_name]);
    }
  }

  slideChanging($event: any) {
    const currentReportCard = this.reportCards?.[$event.nextIndex]?.report_name + '-gradient-container';
    this.headerService.shareCurrentReportNameForStyling(currentReportCard);
  }
}
