<div class="container mx-auto p-6">
  <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left text-[#101010] mb-8">
    Get Your Name Correction Report
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8 px-8 py-12">
    <!-- Loop starts here -->
    <div *ngFor="let item of nameCorrectionData; let i = index" class="flex items-center odd-gradient-border gap-[10px]">
      <!-- Icon -->
      <div class='image-container h-[72px] w-[100px]'>
        <img [src]="item.icon" class="h-[40px] w-[40px]">
      </div>

      <!-- Text Content -->
      <div class="ml-10 mr-10">
        <!-- Title with gradient text color -->
        <div class="font-poppins text-[20px] font-semibold leading-[30px] bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450] bg-clip-text text-transparent">
          {{ item.title }}
        </div>
        <div class="font-poppins text-[16px] font-[500px] leading-[24px] text-left text-[#484848] mt-6">{{ item.description }}</div>
      </div>
    </div>
    <!-- Loop ends here -->
  </div>
</div>
