<section class="w-full h-auto px-[135px] py-[72px] bg-pink-100 mt-24 mb-10 flex flex-col items-center justify-center pt-8 gap-[30px] sample-report-section">
  <!-- Title -->
  <div class="font-poppins text-[40px] font-semibold leading-[56px] text-center text-[#101010]">
    Want to See a Sample Report?
  </div>

  <!-- Description -->
  <p class="font-poppins text-[20px] leading-[30px] text-center text-[#484848] mb-6 w-full max-w-2xl">
    We've got you covered. Just enter your email address below and we'll send you a sample right away!
  </p>

  <!-- Email input and button -->
  <div class="flex flex-row items-center">
    <form [formGroup]="sampleEmailReportForm">
      <div [ngClass]="{'invalid-shake': sampleEmailReportForm.get('email')?.hasError('pattern')}">
        <ejs-textbox
          placeholder="Email Address"
          class="sample-report-email-input"
          formControlName="email">
        </ejs-textbox>
      </div>
    </form>
    <button (click)="sendSampleReportOnEmail()" class="bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450] p-2.5 ms-2 text-sm font-medium text-white rounded-lg border border-pink-700">
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-arrow-right mx-2" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
      </svg>
      <span class="sr-only">Submit</span>
    </button>
  </div>
</section>
