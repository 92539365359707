<section>
    <div class="s-container">
        <div class="pt-12 pr-12 pl-12">
            <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left mb-8 text-[#101010]">
              Our Services
            </div>
            <p-carousel [value]="services" [numVisible]="5" [numScroll]="1" class="service-carousel">
                <ng-template pTemplate="item" let-data>
                   <div class="mx-auto cursor-pointer rounded-custom shadow-custom-shadow
                   w-[270px] h-[274px] px-8 py-4"  (click)="navigateFromServices(data.href)">
                      <img src="{{data.image}}" class="w-16 mt-8">
                      <h2 class="text-base mt-4">{{data.title}}</h2>
                      <p class="text-md  mt-2">{{data.description}}</p>
                   </div>
                </ng-template>
             </p-carousel>
        </div>
    </div>
</section>
