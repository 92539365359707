<div class="container mx-auto p-6">
  <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left text-[#101010] mb-8">
    Get Your Kids Astrology Report
  </div>

  <div class="flex flex-wrap justify-between gap-x-6 gap-y-8">
    <div *ngFor="let card of cards; let i = index" [ngClass]="getCardClasses(i)" [style]="getGradientBorder(i)">
      <div class="flex items-start rounded-full mb-4">
        <!-- Replace with icon as per your design -->
        <img [src]="card.logo" alt="Icon" />
      </div>
      <div class="font-poppins text-[20px] font-semibold leading-[30px] text-left text-[#101010] mb-4">
        {{ card.title }}
      </div>
      <div class="font-poppins text-[16px] font-normal leading-[24px] text-left text-[#484848]">
        {{ card.description }}
      </div>
    </div>
  </div>
</div>