<div class="container mx-auto p-6">
  <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left text-[#101010] mb-8">
    Get Your Kids Numerology Report
  </div>
  <div class="grid grid-cols-1 md:grid-cols-3">
    <!-- Loop starts here -->
    <div *ngFor="let item of kidNumerologyDetails; let i = index"
         class="flex flex-col items-center gap-[10px] mb-10 p-6">
      <!-- Icon -->
      <div class="image-container h-[72px] w-[80px]">
        <img [src]="item.icon" class="h-[40px] w-[40px] mx-auto">
      </div>

      <!-- Text Content -->
      <div class="text-center">

        <div class="flex flex-row">
          <div class="flex-col">
            <div
              class="font-poppins text-[20px] font-semibold leading-[30px] text-[#101010]">
              {{ item.title }}
            </div>
            <div class="font-poppins text-[16px] font-medium leading-[24px] text-[#484848] mt-4 mb-4">
              {{ item.description }}
            </div>
          </div>
          <div class="right-border p-4 h-[185px]"  [ngClass]="{
            'right-border': (i + 1) % 3 != 0}">
          </div>
        </div>
        <div *ngIf="i === 1" [ngClass]="{
           'short-bottom-border': i === 1
          }">

        </div>
      </div>
    </div>
    <!-- Loop ends here -->
  </div>
</div>
