import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';
import {Endpoint, KundaliBasicDetails} from "../../common/settings/app-config";
import {BrowserStorageService} from "../../common/services/browser-storage.service";

@Injectable({
  providedIn: 'root'
})
export class KundaliGeneratorService {
  private submitBasicKundliDetails = new Subject<any>();
  submitBasicKundaliDetails$ = this.submitBasicKundliDetails.asObservable();

  constructor(private http: HttpClient, public browserStorageService: BrowserStorageService) {
  }

  postKundaliBasicDetails(data: any) {
    const URL = `${Endpoint}${KundaliBasicDetails}`
    let authToken = this.browserStorageService.getLocalStorageKey('token');
    const headers = new HttpHeaders({
      Authorization: "Bearer " + authToken,
    });
    delete data.birth_date;
    this.http.post<any>(URL, data, {headers: headers})
      .subscribe({
        next: (data: any) => {
          if (data !== undefined && data !== null) {
            this.submitBasicKundliDetails.next(data);
          }
        }
      })
  }
}
