import {Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import { CommonModule } from '@angular/common';
import {ReportService} from "./report.service";
import {ReportHeader, ReportList} from "./model/report";
import {Subscription} from "rxjs";
import {ReportCardsComponent} from "../report-cards/report-cards.component";
import {CarouselModule, SelectingEventArgs, TabModule, TabComponent} from "@syncfusion/ej2-angular-navigations";
import {HeaderComponent} from "../../common/component/header/header.component";
import {HeaderService} from "../../common/component/header/header.service";
import {CapitalizeFirstLetterPipe} from "../../common/pipes/capitalize-first-letter.pipe";
import {
  WhyWeAreWeDifferentNumerologyComponent
} from "../why-we-are-we-different-numerology/why-we-are-we-different-numerology.component";
import {
  WhyDifferentReportSectionComponent
} from "../why-different-report-section/why-different-report-section.component";
import {
  WhyWeAreDifferentKidAstroReportSectionComponent
} from "../why-we-are-different-kid-astro-report-section/why-we-are-different-kid-astro-report-section.component";
import {FeaturedPublicationsComponent} from "../featured-publications/featured-publications.component";
import {
  GetYourNumerologyReportComponent
} from "../get-your-report-section/get-your-numerology-report/get-your-numerology-report.component";
import {
  GetYourAstrologyReportComponent
} from "../get-your-report-section/get-your-astrology-report/get-your-astrology-report.component";
import {
  GetYourKidAstrologyReportComponent
} from "../get-your-report-section/get-your-kid-astrology-report/get-your-kid-astrology-report.component";
import {
  GetYourKidNumerologyReportComponent
} from "../get-your-report-section/get-your-kid-numerology-report/get-your-kid-numerology-report.component";
import {
  GetYourNameCorrectionReportComponent
} from "../get-your-report-section/get-your-name-correction-report/get-your-name-correction-report.component";
import {FaqSectionComponent} from "../faq-section/faq-section.component";
import {SampleReportSectionComponent} from "../sample-report-section/sample-report-section.component";
import {DiscountBarComponent} from "../../common/component/discount-bar/discount-bar.component";

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [HeaderComponent, CommonModule, ReportCardsComponent, CarouselModule, TabModule, CapitalizeFirstLetterPipe, WhyWeAreWeDifferentNumerologyComponent,
    WhyDifferentReportSectionComponent, WhyWeAreDifferentKidAstroReportSectionComponent, FeaturedPublicationsComponent, GetYourNumerologyReportComponent, GetYourAstrologyReportComponent, GetYourKidAstrologyReportComponent, GetYourKidNumerologyReportComponent, GetYourNameCorrectionReportComponent, FaqSectionComponent, SampleReportSectionComponent, DiscountBarComponent],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent implements OnInit, OnDestroy {
  @Output() reportTypeChange = new EventEmitter<string>();
  public reportType: string = '';
  public currentReport: ReportList[] = [];
  public reportListDetails$ = new Subscription();
  public reportHeader: ReportHeader[] = [];
  public reportList: ReportList[] = [];
  @ViewChild('reportHeaderTabRef') reportHeaderTabRef!: TabComponent;
  isSolid = false;
  public reportBackgroundStyle: string = 'default-gradient-container';
  public reportStyling$: Subscription = new Subscription();

  constructor(private route: ActivatedRoute, public reportService: ReportService, public router: Router, private headerService: HeaderService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.reportType = params['report-type'];
    });
    this.reportBackgroundStyle = 'default-gradient-container';
    this.reportStyling$ = this.headerService.reportStyling$.subscribe((reportName: string) => {
      if (reportName) {
        this.reportBackgroundStyle = reportName;
      }
    })
    this.reportListDetails$ = this.reportService.reportListDetails$.subscribe((reportList: ReportList[]) => {
      if (reportList) {
        this.reportHeader = reportList.map(report => ({
          text: this.capitalizeWords(report.report_name.replace(/-/g, ' '))
        }));
        this.reportList = reportList;
        this.reportBackgroundStyle = this.reportType + '-gradient-container';
        this.getCurrentReportDetails();
      }
    })
    this.reportService.getReportListDetails();

  }

  capitalizeWords(text: string): string {
    return text.replace(/\b\w/g, char => char.toUpperCase());
  }

  ngOnDestroy() {
    if (this.reportListDetails$) {
      this.reportListDetails$.unsubscribe();
    }
    if (this.reportStyling$) {
      this.reportStyling$.unsubscribe();
    }
  }

  onSelectingTabs(event: SelectingEventArgs) {
    if (event && event?.selectedIndex !== undefined && event?.selectedIndex !== null && event?.isInteracted) {
      const currentReport = this.reportList[event.selectedIndex];
      if (currentReport && currentReport?.report_name && currentReport?.report_name !== '') {
        this.reportType = currentReport.report_name;
        this.reportBackgroundStyle = currentReport.report_name + '-gradient-container';
        this.getCurrentReportDetails();
        this.router.navigate(['/report/', this.reportType]);
        this.reportTypeChange.emit(this.reportType);
      }
    }
  }

  getCurrentReportDetails() {
    const currentReport = this.reportList.find(report => report.report_name === this.reportType);
    const currentIndex = this.reportList.findIndex(report => report.report_name === this.reportType);
    if (currentReport) {
      this.currentReport = [];
      this.currentReport.push(currentReport);
    }
    if (currentIndex !== -1 && this.reportHeaderTabRef) {
      this.reportHeaderTabRef.selectedItem = currentIndex;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isSolid = window.scrollY > 100;
  }
}
