<div class="pt-24">
  <section class="flex flex-col items-center">
    <!-- Title -->
    <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left mb-8 text-[#101010]">
      Get your Astrological report
    </div>

    <!-- Container for Central Image and Cards -->
    <div class="relative flex items-center justify-center w-full max-w-6xl mt-20">
      <!-- Central Image -->
      <div class="absolute w-[410px] h-[410px] flex items-center justify-center mr-[20px]">
        <img src="assets/home/report-circle.svg" alt="Icon" class="object-cover" />
      </div>

      <!-- Cards around the Central Image -->
      <div class="grid grid-cols-2 gap-x-20 gap-y-8 z-10">
        <!-- Top Left Card -->
        <div
          class="bg-white shadow-astro-card-shadow rounded-lg p-6 flex items-center space-x-4 w-full md:w-[45%] lg:w-[500px] h-[126px]">
          <img src="assets/home/astro-report-f1.svg" alt="Icon" class="w-12 h-12" />
          <div>
            <div class="font-poppins text-[20px] font-semibold leading-[30px] text-left text-transparent bg-clip-text bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450]">Birth chart & natal horoscopes</div>
            <div class="font-poppins text-[16px] font-medium leading-[24px] text-left text-[#484848] mt-2">
              The unique snapshot of the cosmos the moment when you were born!
            </div>
          </div>
        </div>

        <!-- Top Right Card -->
        <div
          class="bg-white shadow-astro-card-shadow rounded-lg p-6 flex items-center space-x-4 w-full md:w-[45%] lg:w-[500px] h-[126px]">

          <div>
            <div class="font-poppins text-[18px] font-semibold leading-[30px] text-left text-transparent bg-clip-text bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450]">Inside scoop on your health & wellness</div>
            <div class="font-poppins text-[16px] font-medium leading-[24px] text-left text-[#484848] mt-2">
              Gain valuable insights into what your natal Kundli reveals about your health.
            </div>
          </div>
          <img src="assets/home/astro-report-f2.svg" alt="Icon" class="w-12 h-12" />
        </div>

        <!-- Middle Left Card -->
        <div
          class="bg-white shadow-astro-card-shadow rounded-lg p-6 flex items-center space-x-4 w-full md:w-[45%] lg:w-[398px] h-[159px] ml-[-3px]">
          <img src="assets/home/astro-report-f3.svg" alt="Icon" class="w-12 h-12" />
          <div>
            <div class="font-poppins text-[18px] font-semibold leading-[30px] text-left text-transparent bg-clip-text bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450]">Your true self & personality</div>
            <div class="font-poppins text-[16px] font-medium leading-[24px] text-left text-[#484848] mt-2">
              Understand your unique personality traits and real self, as per your Vedic birth-chart.
            </div>
          </div>
        </div>

        <!-- Middle Right Card -->
        <div
          class="bg-white shadow-astro-card-shadow rounded-lg p-6 flex items-center space-x-4 w-full md:w-[45%] lg:w-[398px] h-[159px] ml-[103px]">

          <div>
            <div class="font-poppins text-[18px] font-semibold leading-[30px] text-left text-transparent bg-clip-text bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450]">Your cheat code to success</div>
            <div class="font-poppins text-[16px] font-medium leading-[24px] text-left text-[#484848] mt-2">
              Empower yourself by using your natural strengths - A career or business that you can shine in!
            </div>
          </div>
          <img src="assets/home/astro-report-f4.svg" alt="Icon" class="w-12 h-12" />
        </div>

        <!-- Bottom Left Card -->
        <div
          class="bg-white shadow-astro-card-shadow rounded-lg p-6 flex items-center space-x-4 w-full md:w-[45%] lg:w-[500px] h-[126px]">
          <img src="assets/home/astro-report-f5.svg" alt="Icon" class="w-12 h-12" />
          <div>
            <div class="font-poppins text-[18px] font-semibold leading-[30px] text-left text-transparent bg-clip-text bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450]">Get to know your love life better</div>
            <div class="font-poppins text-[16px] font-medium leading-[24px] text-left text-[#484848] mt-2">
              Did you know that your horoscope can reveal negative karmic patterns that can keep repeating in your love
              life?
            </div>
          </div>
        </div>

        <!-- Bottom Right Card -->
        <div
          class="bg-white shadow-astro-card-shadow rounded-lg p-6 flex items-center space-x-4 w-full md:w-[45%] lg:w-[500px] h-[126px]">
          <div>
            <div class="font-poppins text-[18px] font-semibold leading-[30px] text-left text-transparent bg-clip-text bg-gradient-to-l from-[#EF8248] via-[#E33C49] to-[#BE2450]">Your lucky colours & gemstones</div>
            <div class="font-poppins text-[16px] font-medium leading-[24px] text-left text-[#484848] mt-2">
              Align your destiny with cosmic power with lucky colours and gemstones.
            </div>
          </div>
          <img src="assets/home/astro-report-f6.svg" alt="Icon" class="w-12 h-12" />
        </div>
      </div>
    </div>
  </section>
</div>
