import {Component} from '@angular/core';
import {HeaderComponent} from "../../common/component/header/header.component";
import {NgForOf, NgIf} from "@angular/common";
import {FooterComponent} from "../../common/component/footer/footer.component";

@Component({
  selector: 'app-refund-policy',
  standalone: true,
  imports: [
    HeaderComponent,
    NgForOf,
    NgIf,
    FooterComponent
  ],
  templateUrl: './refund-policy.component.html',
  styleUrl: './refund-policy.component.scss'
})
export class RefundPolicyComponent {
  refundPolicy = [
    {
      title: "1. Refund Eligibility",
      items: [
        {
          description: "Refunds are available for services that have not been initiated. Once a consultation has been conducted or a report has been generated, refunds cannot be issued."
        },
        {
          description: "If you encounter an issue with our services or believe that you have received a defective product, please contact us within 7 days of the service completion or report delivery."
        },
      ]
    },
    {
      title: "2. How to Request a Refund",
      items: [
        {
          description: `To initiate a refund, please reach out to our customer support team at <a href="mailto:support@staarvani.com" class="text-blue-900 underline">support@staarvani.com</a> with your order details.`
        },
        {
          description: "Include your name, order number, and a brief description of the issue."
        },
      ]
    },
    {
      title: "2. Processing Time",
      items: [
        {
          description: "Once your refund request is approved, please allow 5-7 business days for the funds to be processed and returned to your original form of payment."
        },
        {
          description: "Processing times may vary based on your bank or payment provider."
        },
      ]
    },
    {
      title: "4. Non-Refundable Services",
      items: [
        {
          description: "Please note that any consultation services or personalized reports that have been accessed or downloaded are non-refundable."
        },
      ]
    },
    {
      title: "Contact Us",
      description: `If you have any questions or concerns regarding our return and refund policy, feel free to reach out to us at <a href="mailto:support@staarvani.com" class="text-blue-900 underline">support@staarvani.com</a>.`
    }
  ];
}
