<div>
  <div [ngClass]="reportBackgroundStyle">
    <app-header [isHeaderFixed]="true"></app-header>
    <app-report-cards></app-report-cards>
  </div>
  <app-featured-publications></app-featured-publications>
  <app-our-services></app-our-services>
  <app-what-client-says-about-us></app-what-client-says-about-us>
  <app-kundali-generator></app-kundali-generator>
  <app-numerology-calculator></app-numerology-calculator>
  <app-our-guru></app-our-guru>
  <app-google-reviews></app-google-reviews>
</div>

<app-footer></app-footer>
