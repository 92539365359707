import {Component} from '@angular/core';
import {HeaderComponent} from "../../common/component/header/header.component";
import {NgForOf, NgIf} from "@angular/common";
import {FooterComponent} from "../../common/component/footer/footer.component";

@Component({
  selector: 'app-cancellation-policy',
  standalone: true,
  imports: [
    HeaderComponent,
    NgForOf,
    NgIf,
    FooterComponent
  ],
  templateUrl: './cancellation-policy.component.html',
  styleUrl: './cancellation-policy.component.scss'
})
export class CancellationPolicyComponent {
  cancellationPolicyData = [
    {
      title: "1. Astrological and Numerological Report",
      description: "Given the personalized nature of our astrological and numerological reports, cancellations or updates to the report can only be made within the first 2 hours after purchase.",
      items: [
        {
          title: 'Cancellation Process:',
          items: [
            {
              description: `To cancel or request updates within the first 2 hours, please mail us immediately at <a href="mailto:support@staarvani.com" class="text-blue-900 underline">support@staarvani.com</a> <br> or call on +91 9002700221.`
            },
            {
              description: 'After the initial 2-hour period, the report will be in the process of creation, and cancellations or updates cannot be accommodated.'
            }
          ]
        },
      ]
    },
    {
      title: "2. E-Commerce Store Products",
      description: "For products purchased from our e-commerce store, cancellations can only be made within 30 minutes of placing your order.",
      items: [
        {
          title: 'Cancellation Process:',
          items: [
            {
              description: 'To cancel your order within 30 minutes, please go to your account and initiate order cancellation. After 30 minutes the option will not be available.'
            },
            {
              description: 'Once the 30-minute window has passed, we may not be able to cancel your order as it may have already been processed or shipped.'
            }
          ]
        },
        {
          title: 'General Terms:',
          items: [
            {
              description: 'Cancellations are subject to the policies outlined above.'
            }
          ]
        }
      ]
    },
  ];
}
