import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DiscountBarService } from './discount-bar.service';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-discount-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './discount-bar.component.html',
  styleUrls: ['./discount-bar.component.scss']
})
export class DiscountBarComponent implements OnInit, OnDestroy, OnChanges {
  remainingTime: number = 0;
  days: string = '00';
  hours: string = '00';
  minutes: string = '00';
  seconds: string = '00';
  showDiscountBar: boolean = false; // Hide initially
  discountDetails: any;
  promotionDetailsSubscription$: Subscription = new Subscription();
  @Input() reportType: string = '';

  constructor(private countdownService: DiscountBarService, private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reportType']) {
      console.log('ReportType changed:', this.reportType);
      this.reportType = this.getReportType();
      // Handle the change in reportType here
      this.updateDiscountDetails();
    }
  }

  getReportType() {
    if (this.reportType === 'numerology') {
      this.reportType = 'numerology_report_standard';
    } else  if (this.reportType === 'kid-numerology') {
      this.reportType = 'kid_numerology_report_standard';
    } else  if (this.reportType === 'astrology') {
      this.reportType = 'astro_report_standard';
    } else  if (this.reportType === 'kid-astrology') {
      this.reportType = 'kid_astro_report_standard';
    } else if (this.reportType === 'name-correction') {
      this.reportType = 'name_correction_standard';
    }
    return this.reportType;
  }

  ngOnInit(): void { }

  updateDiscountDetails() {
    this.promotionDetailsSubscription$ = this.countdownService.promotionDetails$.subscribe({
      next: (data) => {
        if (data) {
          this.discountDetails = data['discount_data'];
          console.log(this.discountDetails, 'this.discountDetails');
          if (data.is_discount === true) {
            this.remainingTime = this.discountDetails.time_remaining;
            // Show the discount bar only if there is remaining time
            console.log(this.remainingTime, 'this.remainingTime');
            if (this.remainingTime > 0) {
              this.showDiscountBar = true;
              this.startCountdown();
            } else {
              this.showDiscountBar = false;
            }
          } else {
            this.showDiscountBar = false;
          }
        }
      }
    });
    this.countdownService.fetchPromotionDetails(this.reportType, "Asia/Kolkata");
  }

  startCountdown(): void {
    console.log("enter");
    const timerInterval = setInterval(() => {
      if (this.remainingTime <= 0) {
        clearInterval(timerInterval);
        this.showDiscountBar = false; // Hide the discount bar when time ends
        return;
      }

      const days = Math.floor(this.remainingTime / (60 * 60 * 24));
      const hours = Math.floor((this.remainingTime % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((this.remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(this.remainingTime % 60);

      this.days = String(days).padStart(2, '0');
      this.hours = String(hours).padStart(2, '0');
      this.minutes = String(minutes).padStart(2, '0');
      this.seconds = String(seconds).padStart(2, '0');

      this.remainingTime--; // Decrease the time by 1 second
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.promotionDetailsSubscription$) {
      this.promotionDetailsSubscription$.unsubscribe();
    }
  }

  closeDiscountBar(): void {
    this.showDiscountBar = false;
  }
}
