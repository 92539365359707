import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';

@Component({
  selector: 'app-our-services',
  standalone: true,
  imports: [CommonModule, CarouselModule],
  templateUrl: './our-services.component.html',
  styleUrl: './our-services.component.scss'
})
export class OurServicesComponent implements OnInit {
  services = [
    {
      "image": "../../../assets/home/icn_report.svg",
      "title": "Reports",
      "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "href": "report-details",
    },
    {
      "image": "../../../assets/home/icn_horoscope.svg",
      "title": "Horoscope",
      "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "href": "horoscope-details",
    },
    {
      "image": "../../../assets/home/icn_kundali.svg",
      "title": "Kundli",
      "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "href": "kundli"
    },
    {
      "image": "../../../assets/home/numerology-icn.svg",
      "title": "Numerology",
      "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "href": "numerology"
    },
    {
      "image": "../../../assets/home/icn_baby_names.svg",
      "title": "Baby Names",
      "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "href": "baby-names"
    },
    {
      "image": "../../../assets/home/icn_panchang.svg",
      "title": "Panchang",
      "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "href": ""
    },
    {
      "image": "../../../assets/home/festivals.png",
      "title": "Astrology",
      "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "href": ""
    },
    {
      "image": "../../../assets/home/job.png",
      "title": "Namaank",
      "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "href": ""
    }
  ]

  constructor(private router: Router) {
  }

  ngOnInit(): void {}

  navigateFromServices(link: string): void {
    this.router.navigate([link]);
  }

}
