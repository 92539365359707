import { Component } from '@angular/core';
import {HeaderComponent} from "../header/header.component";
import {CopyRightSectionComponent} from "../copy-right-section/copy-right-section.component";

@Component({
  selector: 'app-pagelayout',
  standalone: true,
  imports: [HeaderComponent, CopyRightSectionComponent],
  templateUrl: './pagelayout.component.html',
  styleUrl: './pagelayout.component.scss'
})
export class PagelayoutComponent {

}
