<div class="default-gradient-container">
  <app-header [isHeaderFixed]="true"></app-header>

  <div class="s-container pt-24">
    <section class="text-center py-8">
      <div class="text-[60px] font-bold text-[#101010] font-poppins leading-[78px]">Refund Policy</div>
      <nav class="mt-2">
        <span class="text-[#101010] font-normal text-[18px] font-inter leading-[27px]">Home &gt;</span>
        <span class="font-normal bg-clip-text text-transparent bg-button-gradient text-[18px] font-inter leading-[27px]"> Refund Policy</span>
      </nav>
    </section>
  </div>
</div>
<div class="s-container">
  <div class="bg-[#FBDEE2] rounded-lg p-[32px] mb-10">
    <div class="p-[32px] max-w-7xl mx-auto bg-white rounded-lg shadow-lg">
      <div *ngFor="let section of refundPolicy" class="mb-6">
        <p class="font-poppins text-[24px] text-[#101010] text-justify font-semibold mb-4 leading-[36px]">{{ section.title }}</p>
        <p *ngIf="section?.description" class="font-poppins text-[18px] font-normal text-[#484848]" [innerHTML]="section?.description"></p>
        <!-- Description and Bullet Points -->
        <ul *ngIf="section?.items" class="mt-2 font-poppins text-[18px] text-justify mb-2 leading-[27px]">
          <li *ngFor="let item of section?.items" class="flex mb-2">
            <img src="assets/images/privacy-policy/privacy-bullet.png" class="h-[9px] w-[9px] mt-[9px]" alt="" />
            <span class="ml-3 text-[#484848] font-normal" [innerHTML]="item?.description"></span>
          </li>
        </ul>
      </div>
      <p class="font-poppins text-[18px] font-normal text-[#484848]">
        <span>Thank you for choosing </span>
        <span><a class="text-blue-900 underline" href="https://staarvani.com/" target="_blank">Staarvani.com</a></span>
        <span> We appreciate your understanding and support!</span>
      </p>
    </div>
  </div>
</div>

<app-footer></app-footer>
