import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {ReportList} from "./model/report";
import {CommunicatorService} from "../../common/services/communicator.service";
import {Endpoint} from "../../common/settings/app-config";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public reportList?: ReportList[];
  public reportListDetails = new Subject<ReportList[]>();
  public reportListDetails$ = this.reportListDetails.asObservable();

  constructor(public communicatorService: CommunicatorService) {
  }

  getReportListDetails() {
    this.communicatorService.getData(Endpoint + 'reports').subscribe({
      next: response => {
        this.reportList = this.manipulateReportList(response);
        this.reportListDetails.next(this.reportList);
      }
    });
  }

  manipulateReportList(reportList: ReportList[]): ReportList[] {
    if (reportList && reportList.length > 0) {
      reportList.forEach((report) => {
        if (report?.feature && report?.feature?.home_screen) {
          report.feature.home_screen.link = "/report/";
        }
      });
    }
    return reportList;
  }
}
