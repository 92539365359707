import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-get-your-kid-numerology-report',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './get-your-kid-numerology-report.component.html',
  styleUrl: './get-your-kid-numerology-report.component.scss'
})
export class GetYourKidNumerologyReportComponent {

  kidNumerologyDetails = [
    {
      icon: 'assets/images/kid-numerology-report/life-path-number-analysis.png',
      title: 'Life Path Number Analysis',
      description: 'Learn about your child’s core number, the Life Path Number, which reveals their destiny, talents, and the path they are most likely to take in life. ' +
        'This insight helps guide them toward their natural strengths and life purpose from an early age.',
      color: 'red-600'
    },
    {
      icon: 'assets/images/kid-numerology-report/personality-insights.png',
      title: 'Personality Insights',
      description: 'Explore the Expression Number, which shows how your child presents themselves to the world, and the Soul Urge Number, which reflects their innermost desires. ' +
        'These numbers help you understand their unique personality traits and emotional needs, allowing you to nurture their confidence and individuality.',
      color: 'red-600'
    },
    {
      icon: 'assets/images/kid-numerology-report/academic-and-career-potential.png',
      title: 'Academic and Career Potential',
      description: 'Discover how your child’s numbers indicate their intellectual strengths, learning style, and career inclinations. ' +
        'This information helps you support their educational journey and future career choices by aligning them with their natural talents and interests.',
      color: 'orange-500'
    },
    {
      icon: 'assets/images/kid-numerology-report/health-and-well-being-guidance.png',
      title: 'Health and Well-Being Guidance',
      description: 'Uncover how numerology can shed light on your child’s physical and emotional health. ' +
        'Learn about the best environments and activities that promote well-being based on their numbers, allowing you to support their growth and vitality.',
      color: 'red-600'
    },
    {
      icon: 'assets/images/kid-numerology-report/parent-child-compatibility.png',
      title: 'Parent-Child Compatibility',
      description: 'Understand how your child’s numerology aligns with yours and other family members’. ' +
        'This helps in building stronger relationships by appreciating each other\'s differences and creating harmony in the home environment.',
      color: 'red-600'
    },
    {
      icon: 'assets/images/kid-numerology-report/future-forecasts-and-remedies.png',
      title: 'Future Forecasts and Remedies',
      description: 'Get a glimpse into your child’s future opportunities and challenges. ' +
        'The report offers numerology-based remedies and practices that you can implement to enhance your child’s life, balance energies, and help them navigate challenges more effectively.',
      color: 'red-600'
    }
  ];
}
