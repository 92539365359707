import {Component} from '@angular/core';
import {HeaderComponent} from "../../common/component/header/header.component";
import {NgForOf, NgIf} from "@angular/common";
import {FooterComponent} from "../../common/component/footer/footer.component";

@Component({
  selector: 'app-terms-and-condition',
  standalone: true,
  imports: [
    HeaderComponent,
    NgForOf,
    NgIf,
    FooterComponent
  ],
  templateUrl: './terms-and-condition.component.html',
  styleUrl: './terms-and-condition.component.scss'
})
export class TermsAndConditionComponent {
  public effectiveDate = 'Effective Date: October 1, 2024';
  public endDate = 'End Date: October 1, 2035';
  termsAndConditionData = [
    {
      title: "1. Acceptance of Terms",
      description: `By using Staarvani, including visiting our website at <a href="https://staarvani.com/" target="_blank" class="text-blue-900 underline">Staarvani.com</a> and utilizing our report services, you agree to these Terms and Conditions. If you do not agree with any part of these terms, you must not use our services.`,
    },
    {
      title: "2. Services Provided",
      description: "Staarvani provides numerology reports and related services based on the information you provide. Our services are intended for informational and entertainment purposes only. We do not guarantee the accuracy, completeness, or results of any report."
    },
    {
      title: "3. User Responsibilities",
      items: [
        {
          title: "Accuracy of Information:",
          description: "You are responsible for providing accurate and complete information when using our services. Any errors or inaccuracies in the information you provide may affect the results of your report."
        },
        {
          title: "Use of Services:",
          description: "You agree to use our services in accordance with applicable laws and regulations. You may not use our services for any unlawful or unauthorized purpose."
        }
      ]
    },
    {
      title: "4. Intellectual Property",
      description: "All content, including but not limited to text, graphics, logos, and software, on Staarvani is the property of AIQuiver Vision LLP or its licensors and is protected by intellectual property laws. You may not use, reproduce, distribute, or create derivative works from any content on Staarvani without express written permission from us."
    },
    {
      title: "5. Disclaimers",
      items: [
        {
          title: "No Guarantees:",
          description: "Staarvani provides numerology reports based on interpretations of numerological principles. We do not guarantee the accuracy, completeness, or results of any report."
        },
        {
          title: "No Professional Advice:",
          description: "Our reports are not a substitute for professional advice, including medical, financial, or legal advice. Always seek the advice of qualified professionals for any such matters."
        }
      ]
    },
    {
      title: "6. Limitation of Liability",
      description: "To the fullest extent permitted by law, AIQuiver Vision LLP, its affiliates, officers, directors, employees, and agents will not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of Staarvani or any reports provided. This includes but is not limited to loss of profits, data, or other intangible losses."
    },
    {
      title: "7. Indemnification",
      description: "You agree to indemnify, defend, and hold harmless AIQuiver Vision LLP and its affiliates from and against any claims, liabilities, damages, losses, costs, or expenses (including reasonable attorneys' fees) arising out of or related to your use of Staarvani or your violation of these Terms and Conditions."
    },
    {
      title: "8. Changes to Terms",
      description: "We reserve the right to modify these Terms and Conditions at any time. When we make changes, we will post the updated terms on our website with a revised effective date. Your continued use of our services after changes are made constitutes your acceptance of the updated terms."
    },
    {
      title: "9. Termination",
      description: "We may terminate or suspend your access to Staarvani at our sole discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users or third parties."
    },
    {
      title: "10. Governing Law",
      description: "These Terms and Conditions are governed by and construed in accordance With the local laws.. Any disputes arising from or relating to these terms will be subjected to the jurisdiction of the regional courts."
    },
    {
      title: "Contact Information",
      subItems: [
        {
          address: 'AIQuiver Vision LLP',
          iconUrl: 'assets/images/privacy-policy/privacy-policy-company.png'
        },
        {
          address: `<a href="mailto:support@staarvani.com" class="text-blue-900 underline">support@staarvani.com</a>`,
          iconUrl: 'assets/images/privacy-policy/privacy-policy-email.png'
        },
        {
          address: '48B Sports Villa, Jaypee Greens, Greater Noida 201310',
          iconUrl: 'assets/images/privacy-policy/privacy-policy-location.png'
        }
      ]
    }];
}
