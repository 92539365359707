<div class="default-gradient-container">
  <app-header [isHeaderFixed]="true"></app-header>

  <div class="s-container pt-24">
    <section class="text-center py-8">
      <div class="text-[60px] font-bold text-[#101010] font-poppins leading-[78px]">Cancellation Policy</div>
      <nav class="mt-2">
        <span class="text-[#101010] font-normal text-[18px] font-inter leading-[27px]">Home &gt;</span>
        <span class="font-normal bg-clip-text text-transparent bg-button-gradient text-[18px] font-inter leading-[27px]"> Cancellation Policy</span>
      </nav>
    </section>
  </div>
</div>
<div class="s-container">
  <div class="bg-[#FBDEE2] rounded-lg p-[32px] mb-10">
    <div class="p-[32px] max-w-7xl mx-auto bg-white rounded-lg shadow-lg">
      <div *ngFor="let policy of cancellationPolicyData" class="mb-6">
        <p class="font-poppins text-[24px] text-[#101010] text-justify font-semibold mb-4 leading-[36px]">{{ policy.title }}</p>
        <p *ngIf="policy?.description" class="font-poppins text-[18px] font-normal text-[#484848]" [innerHTML]="policy?.description"></p>
        <div  *ngIf="policy?.items" class="mt-2 font-poppins text-[18px] text-justify mb-2 leading-[27px]">
          <div *ngFor="let policyItem of policy?.items" class="mb-6">
            <p class="font-poppins text-[20px] text-[#101010] text-justify font-semibold mb-4 leading-[36px]">{{ policyItem.title }}</p>
            <ul *ngIf="policyItem?.items" class="mt-2 font-poppins text-[18px] text-justify mb-2 leading-[27px]">
              <li *ngFor="let subItem of policyItem?.items" class="flex mb-2">
                <img src="assets/images/privacy-policy/privacy-bullet.png" class="h-[9px] w-[9px] mt-[9px]" alt="" />
                <span class="ml-3 text-[#484848] font-normal" [innerHTML]="subItem?.description"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="font-poppins text-[18px] font-normal text-[#484848]">
        <span>Thank you for choosing us. </span>
        <span>For any further assistance or questions regarding cancellations,
          please contact our customer service team at <a href="mailto:support@staarvani.com" class="text-blue-900 underline">support&#64;staarvani.com.</a></span>
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
