<section>
    <div class="s-container">
        <div class="p-12">
            <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left mb-8 text-[#101010]">
              Google Review
            </div>
            <div>
                <p-carousel class="google-review-carousel" [value]="reviews" [numVisible]="3" [numScroll]="1"
                    [circular]="true" [autoplayInterval]="10000">
                    <ng-template pTemplate="item" let-review>
                        <div
                            class="bg-white pt-8 pb-8 pl-6 pr-6 rounded-custom text-center max-w-xs mx-auto w-[420px] h-[382px] shadow-custom-shadow flex flex-col justify-between">
                            <div>
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center space-x-2">
                                        <img [src]="review.reviewerImage" alt="{{ review.reviewerName }}"
                                            class="rounded-full w-12 h-12 object-cover" />
                                        <div class="text-left">
                                            <div
                                                class="text-[20px] font-medium leading-[30px] text-left text-[#101010] font-poppins">
                                                {{ review.reviewerName }}
                                            </div>
                                            <div
                                                class="font-poppins text-[16px] font-normal leading-[24px] text-left text-[#484848]">
                                                {{ review.reviewDate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-center mt-4 space-x-1">
                                    <i class="pi pi-star-fill text-yellow-400"
                                        *ngFor="let star of [].constructor(review.rating)"></i>
                                </div>
                                <p class="text-gray-600 text-sm mt-4">{{ review.reviewText }}</p>
                            </div>

                            <div class="mt-2 flex justify-center">
                                <img src="../../../assets/home/logo_google.png" alt="Google Logo" class="w-6 h-6" />
                            </div>
                        </div>
                    </ng-template>
                </p-carousel>
            </div>
        </div>
    </div>
</section>
