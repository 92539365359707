<section>
   <div class="s-container">
      <div class="p-12">
         <div>
            <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left text-[#101010] mb-8">
              What Client says about us"
            </div>
         </div>
         <ejs-carousel [dataSource]="chunkedClients" interval="100000" [buttonsVisibility]="'Hidden'">
            <ng-template #itemTemplate let-data>
               <div class="flex justify-center items-center space-x-20 mt-6 pt-12 pb-12 pr-12">
                  <div *ngFor="let client of data; let i = index"
                     class="p-2 w-64 flex flex-col items-center text-center relative mb-[30px]">
                     <ng-container *ngIf="i % 2 === 0; else bottomArrow">
                        <!-- Feedback Section Above Image for odd-index clients -->
                        <div class="relative mb-4">
                           <div
                              class="w-[280px] h-[250px] bg-[#FFFFFF] shadow-what-client-says-cards-shadow rounded-custom p-4 relative">
                              <!-- Triangle pointing downwards at the bottom of the feedback box -->
                              <div class="absolute bottom-[-20px] left-1/2 transform -translate-x-1/2 w-0 h-0
                            border-l-[20px] border-r-[20px] border-t-[20px]
                            border-l-transparent border-r-transparent border-t-[#FFFFFF]">
                              </div>
                              <div class="flex flex-col  mb-4  items-center">
                                 <img src="assets/home/double-cots.png" alt="dots" class="w-[32.1px] h-[32px]">
                              </div>
                              <div class="text-[16px] leading-[24px] text-[#484848]">
                                 {{ client.feedback }}
                              </div>
                           </div>
                        </div>
                        <!-- Image and Name Section -->
                        <div class="flex flex-col items-center mt-8">
                           <img [src]="client.image" alt="{{ client.name }}"
                              class="w-[52px] h-[52px] border-[2px] rounded-full">
                           <div class="text-center mt-2">
                              <div class="text-[18px] leading-[36px] font-semibold">{{ client.name }}</div>
                           </div>
                        </div>
                     </ng-container>

                     <!-- Alternate layout -->
                     <ng-template #bottomArrow>
                        <!-- Image and Name Section Above Feedback for even-index clients -->
                        <div class="flex flex-col items-center mb-4">
                           <img [src]="client.image" alt="{{ client.name }}"
                              class="w-[52px] h-[52px] border-[2px] rounded-full">
                           <div class="text-center mt-2">
                              <div class="text-[18px] leading-[36px] font-semibold">{{ client.name }}</div>
                           </div>
                        </div>
                        <!-- Feedback Section Below Image -->
                        <div class="relative mt-4">
                           <div
                              class="w-[280px]  h-[250px] bg-[#FFFFFF] shadow-what-client-says-cards-drop-shadow rounded-custom p-4 relative">
                              <!-- Triangle pointing upwards at the top of the feedback box -->
                              <div class="absolute top-[-20px] left-1/2 transform -translate-x-1/2 w-0 h-0
                            border-l-[20px] border-r-[20px] border-b-[20px]
                            border-l-transparent border-r-transparent border-b-[#FFFFFF]">
                              </div>
                              <div class="flex flex-col mb-4 items-center">
                                 <img src="assets/home/double-cots.png" alt="dots" class="w-[32.1px] h-[32px]">
                              </div>
                              <div class="text-[16px] leading-[24px] text-[#484848]">
                                 {{ client.feedback }}
                              </div>
                           </div>
                        </div>
                     </ng-template>
                  </div>
               </div>
            </ng-template>
         </ejs-carousel>
      </div>
   </div>
</section>
