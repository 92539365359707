import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-get-your-numerology-report',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './get-your-numerology-report.component.html',
  styleUrl: './get-your-numerology-report.component.scss'
})
export class GetYourNumerologyReportComponent {
  numerologyReportCards = [
    {
      title: "Birth chart & natal horoscopes",
      description: "The unique snapshot of the cosmos the moment when you were born!",
      icon: "../../../assets/home/report-menu-icon-1.png", // Add the path for the respective icons
      link: "Read More"
    },
    {
      title: "Get to know your love life better",
      description: "Did you know that your horoscope can reveal negative karmic patterns that can keep repeating in your love life?",
      icon: "../../../assets/home/report-menu.png",
      link: "Read More"
    },
    {
      title: "Your true self & personality",
      description: "Understand your unique personality traits and role, as per your Vedic birth-chart.",
      icon: "../../../assets/home/report-menu-icon-2.png",
      link: "Read More"
    },
    {
      title: "Your karmic lessons await you",
      description: "Discover your karmic life lessons to create everlasting change.",
      icon: "../../../assets/home/report-menu-icon-3.png",
      link: "Read More"
    },
    {
      title: "Inside scoop on your health & wellness",
      description: "Gain valuable insights into what your natal Kundli reveals about your health.",
      icon: "../../../assets/home/report-menu-icon-7.png",
      link: "Read More"
    },
    {
      title: "Your cheat code to success",
      description: "Empower yourself by using your natural strengths - A career or business that you can shine in!",
      icon: "../../../assets/home/report-menu-icon-5.png",
      link: "Read More"
    },
    {
      title: "Your lucky colours & gemstones",
      description: "Align your destiny with cosmic power with lucky colours and gemstones.",
      icon: "../../../assets/home/report-menu-icon-6.png",
      link: "Read More"
    },
    {
      title: "Famous Personalities",
      description: "Find out which celebrities share your unique cosmic alignment!",
      icon: "../../../assets/home/report-menu-icon-7.png",
      link: "Read More"
    }
  ];

   // Function to determine background class
   getBackgroundClass(index: number): string {
    const row = Math.floor(index / 4); // Determine the row based on index
    const column = index % 4; // Determine the column within the row

    // First row (row % 2 === 0): Gradient on even columns, White on odd columns
    // Second row (row % 2 === 1): White on even columns, Gradient on odd columns
    if (row % 2 === 0) {
      return column % 2 === 0 ? 'bg-gradient-alternate' : 'bg-white';
    } else {
      return column % 2 === 0 ? 'bg-white' : 'bg-gradient-alternate';
    }
  }
}
