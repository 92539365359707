<div class="pt-24">
  <section>
    <div class="s-container">
      <div class="container mx-auto px-4">
        <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left text-[#101010] mb-6">
          Get your report
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0 p-0 m-0">
          <div *ngFor="let report of numerologyReportCards; let i = index"
               [ngClass]="getBackgroundClass(i)"
               class="p-6 flex flex-col justify-between h-[350px]">
            <div class="flex-grow">
              <div class="flex justify-start">
                <img [src]="report.icon" alt="Report Icon" class="w-16 h-16 mb-4">
              </div>
              <h2 class="text-[20px] font-semibold leading-[30px] text-[#101010] mb-4">{{ report.title }}</h2>
              <p class="text-[16px] font-normal leading-[24px] text-[#484848] mb-6">{{ report.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
