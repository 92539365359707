import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-why-we-are-we-different-numerology',
  standalone: true,
    imports: [CommonModule],
  templateUrl: './why-we-are-we-different-numerology.component.html',
  styleUrl: './why-we-are-we-different-numerology.component.scss'
})
export class WhyWeAreWeDifferentNumerologyComponent {

  cardData = [
    {
      icon: 'assets/images/why-we-are-different/years-of-experience.jpg',  // Optional icon
      title: 'Years of Experience in Vedic Astrology Simplified',
      description: 'Purposeful interpretations that help you understand where you are in your life, what is your true purpose. The science of “Jyotish” is to illuminate our true paths and move forward with confidence!'
    },
    {
      icon: 'assets/images/why-we-are-different/happy-customer.jpg',  // Optional icon
      title: 'Trusted by over 75K happy customers',
      description: 'We are thrilled to have had the privilege of touching the lives of our many happy customers. Your support keeps us going.'
    },
    {
      icon: 'assets/images/why-we-are-different/no-hidden-cost.jpg',  // Optional icon
      title: 'No Hidden Cost',
      description: 'We will never push you for astrological remedies. No stones and no poojas. Our report provides important astrological guidance for your self-awareness as per the science of Vedic Astrology.'
    },
    {
      icon: 'assets/images/why-we-are-different/no-jargon.jpg',  // Optional icon
      title: 'No Jargon',
      description: 'Our astrologers use easy-to-understand language instead of giving you endless pages of confusing charts and calculations, generic information that you have no use for!'
    }
  ];
}
