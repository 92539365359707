<nav  [ngClass]="{'header-solid': isSolid, 'header-fixed': isHeaderFixed}" class="relative px-4 lg:px-6 py-0 w-full z-10 top-0 start-0">
  <div class="flex flex-wrap justify-between items-center py-[10px] px-[135px]">
    <a href="#" class="flex items-center">
      <img src="assets/images/staarvani-logo.png" class="mr-3 h-10 sm:h-10" alt="Staarvani Logo" />
    </a>
    <div class="flex items-center lg:order-2">
      <button class="bg-button-gradient rounded-xl gap-[10px] py-[16px] px-[32px]" *ngIf="!isUserLoggedIn"
              data-modal-target="authentication-modal" data-modal-toggle="authentication-modal" (click)="login()">
        <div class="leading-[24px] text-center text-[16px] font-[500px] text-white">Login</div>
      </button>

<!--      <button id="dropdownUserAvatarButton" data-dropdown-toggle="myDropdown" class="flex text-sm rounded-full md:me-0 " type="button" *ngIf="isUserLoggedin">-->
<!--        <img class="w-12 h-12 rounded-full"  src="assets/images/profile-image-dummy.png" alt="user photo">-->
<!--        <div *ngIf="isUserLoggedin && !isAgent" class="avatar d-flex justify-content-center align-items-center">-->
<!--          {{ firstLetter }}-->
<!--        </div>-->
<!--        &lt;!&ndash; {{profileImageURL}}=={{isAgent}} &ndash;&gt;-->
<!--        <div *ngIf="isUserLoggedin && isAgent" class="d-flex justify-content-center align-items-center">-->
<!--          <img  *ngIf="profileImageURL" title="userImage" class="w-12 h-12 rounded-full object-cover"  [src]="profileImageURL" />-->
<!--          <img  *ngIf="!profileImageURL" title="userImage" class="w-12 h-12 rounded-full object-cover"  src="assets/images/profile-image-dummy.png" alt="user photo" />-->
<!--        </div>-->
<!--      </button>-->
<!--      <div  *ngIf="isUserLoggedin" id="myDropdown" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">-->
<!--        <div class="px-2 py-3 text-sm  dark:text-white">-->
<!--          <h1 class="text-md user-name m-0 px-1 text-green-600">{{userInfo.user?.properties!=null ? userInfo.user?.properties.name : userInfo.user?.phone_number}}</h1>-->
<!--          <p class="text-xs text-gray-600 m-0 p-0" *ngIf="userInfo.user?.properties!=null">{{userInfo.user?.phone_number}}</p>-->
<!--        </div>-->
<!--        <ul class="py-2 px-0 text-sm text-gray-700 dark:text-gray-200 my-auto" aria-labelledby="dropdownUserAvatarButton">-->
<!--          <li>-->
<!--            <a (click)="openProfile()" class="px-4 py-2 block text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">My Account</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <div class="py-2">-->
<!--          <a  id="deleteButton" data-modal-target="deleteModal" data-modal-toggle="deleteModal"  class="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</a>-->
<!--        </div>-->
<!--      </div>-->

      <button data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
        </svg>
        <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>
    <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
      <ul class="flex flex-col mt-3 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
        <li *ngFor="let menuItem of headerMenuItems">
          <a *ngIf="!menuItem?.hasSubMenu" [href]="menuItem.url"
             class="cursor-pointer block text-[14px] font-bold leading-[21px] py-2 pr-2 text-[#101010] border-b border-gray-100 lg:hover:bg-transparent lg:border-0 lg:p-0 hover:font-extrabold hover:text-[15px]">
            {{ menuItem.name }}
          </a>

          <div *ngIf="menuItem?.hasSubMenu" class="relative">
            <a id="dropdownNavbarLink1" data-dropdown-toggle="dropdownNavbar_1"
               class="cursor-pointer text-[14px] font-bold leading-[21px] text-[#101010] flex items-center justify-between w-full py-2 pr-4 pl-3 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0">
              {{ menuItem.name }}
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
                   class="bi bi-chevron-down ml-1 font-bold" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
              </svg>
            </a>

            <div id="dropdownNavbar_1" class="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              <ul class="py-2 text-md px-2">
                <li *ngFor="let subMenuItem of menuItem?.subMenu">
                  <a *ngIf="subMenuItem?.name" [href]="subMenuItem?.url"
                     class="block text-md text-[14px] font-bold leading-[21px] text-[#101010] py-2 pr-4 pl-3 border-b border-gray-100 lg:hover:bg-transparent lg:border-0 lg:p-0 hover:font-extrabold hover:text-[15px]">
                    {{ subMenuItem?.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>

    </div>
  </div>
</nav>
