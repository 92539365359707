<div *ngIf="showDiscountBar" class="discount-bar w-full py-2 bg-gradient-to-r from-[#EF8248] via-[#E33C49] to-[#BE2450]">
  <div class="mx-auto flex justify-center items-center px-4 space-x-6 relative">
    <!-- Close Button -->
    <button (click)="closeDiscountBar()" class="text-white absolute left-4"> <!-- Adjusted here -->
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd" />
      </svg>
    </button>

    <!-- Countdown Timer -->
    <div class="flex items-center space-x-2">
      <!-- Days -->
      <div class="bg-white rounded-md text-center pt-[6px] pb-[6px] pr-[8px] pl-[8px]">
        <div class="font-bold text-[#E33C49] text-[14px]">{{ days }}</div>
        <div class="text-[10px] text-gray-600">Days</div>
      </div>

      <!-- Colon Divider -->
      <div class="text-white text-[20px] font-bold">:</div>

      <!-- Hours -->
      <div class="bg-white rounded-md text-center pt-[5px] pb-[5px] pr-[8px] pl-[8px]">
        <div class="font-bold text-[#E33C49] text-[14px]">{{ hours }}</div>
        <div class="text-[10px] text-gray-600">Hours</div>
      </div>

      <!-- Colon Divider -->
      <div class="text-white text-[20px] font-bold">:</div>

      <!-- Minutes -->
      <div class="bg-white rounded-md text-center pt-[5px] pb-[5px] pr-[8px] pl-[8px]">
        <div class="font-bold text-[#E33C49] text-[14px]">{{ minutes }}</div>
        <div class="text-[10px] text-gray-600">Mins</div>
      </div>

      <!-- Colon Divider -->
      <div class="text-white text-[20px] font-bold">:</div>

      <!-- Seconds -->
      <div class="bg-white rounded-md text-center pt-[5px] pb-[5px] pr-[8px] pl-[8px]">
        <div class="font-bold text-[#E33C49] text-[14px]">{{ seconds }}</div>
        <div class="text-[10px] text-gray-600">Secs</div>
      </div>
    </div>

    <!-- Promo Text and Button -->
    <div class="flex items-center space-x-4">
      <div class="text-white font-medium text-md">
        {{ discountDetails?.title }}
      </div>
      <button class="px-4 py-2 bg-white text-[#E33C49] font-semibold rounded-full">
        {{ discountDetails?.tag }}
      </button>
    </div>
  </div>
</div>
