import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CarouselModule } from 'primeng/carousel';

interface Review {
  reviewerName: string;
  reviewerImage: string;
  rating: number;
  reviewText: string;
  reviewDate: any;
}


@Component({
  selector: 'app-google-reviews',
  standalone: true,
  imports: [CarouselModule, CommonModule],
  templateUrl: './google-reviews.component.html',
  styleUrl: './google-reviews.component.scss'
})
export class GoogleReviewsComponent {
  reviews: Review[] = [
    {
      reviewerName: 'Desirae Botosh',
      reviewerImage: '../../../assets/home/dummy-member.png', // Replace with actual images
      rating: 5,
      reviewText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
      reviewDate: '30/07/2024',
    },
    {
      reviewerName: 'Terry Dias',
      reviewerImage: '../../../assets/home/dummy-member.png',
      rating: 5,
      reviewText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
      reviewDate: '30/07/2024',
    },
    {
      reviewerName: 'Mira Mango',
      reviewerImage: '../../../assets/home/dummy-member.png',
      rating: 5,
      reviewText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
      reviewDate: '29/07/2024',
    },
    {
      reviewerName: 'Mira MJ',
      reviewerImage: '../../../assets/home/dummy-member.png',
      rating: 5,
      reviewText: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
      reviewDate: '29/07/2024',
    },
  ];
}
