import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-faq-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './faq-section.component.html',
  styleUrl: './faq-section.component.scss'
})
export class FaqSectionComponent {
  faqs = [
    {
      question: "What can I expect from my Astrology report?",
      answer: "Your Astrology report provides a comprehensive analysis of your birth chart...",
      open: true
    },
    {
      question: "How is the Astrology report created?",
      answer: "The Astrology report is created by analyzing your birth chart...",
      open: false
    },
    {
      question: "Do I need to know my exact birth time?",
      answer: "Yes, your exact birth time allows for a more accurate analysis...",
      open: false
    },
    {
      question: "Can my Astrology report predict my future?",
      answer: "Astrology reports give you insights into trends, but they do not predict your future with certainty...",
      open: false
    }
  ];
  openIndex: number | null = 0; // Initially open the first FAQ

  toggleFAQ(index: number) {
    if (this.openIndex === index) {
      this.openIndex = null; // Close if the same question is clicked
    } else {
      this.openIndex = index; // Open the clicked question and close others
    }
  }

}
