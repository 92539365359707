import {CommonModule} from '@angular/common';
import {Component, HostListener, Input, OnInit,} from '@angular/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  public headerMenuItems = [
    {name: 'Home', url: '', hasSubMenu: false, subMenu: []},
    {name: 'Numerology', url: '', hasSubMenu: false, subMenu: []},
    {name: 'About', url: '/about', hasSubMenu: false, subMenu: []},
    {name: 'Services', url: '/services', hasSubMenu: true, subMenu: [
        {name: 'Horoscope', url: '', hasSubMenu: false, subMenu: []},
        {name: 'Kundali', url: '/kundli', hasSubMenu: false, subMenu: []},
        {name: 'Baby Names', url: '/baby-names', hasSubMenu: false, subMenu: []}
      ]},
    {name: 'Astrology', url: '', hasSubMenu: false, subMenu: []},
  ]

  isSolid = false;
  @Input() isHeaderFixed = true;
  isUserLoggedIn: boolean = false;


  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isSolid = window.scrollY > 100;
  }


  constructor() {
  }

  ngOnInit(): void {

  }

  login() {

  }
}

