import {Routes} from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {ReportComponent} from "./pages/report/report.component";
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {TermsAndConditionComponent} from "./pages/terms-and-condition/terms-and-condition.component";
import {RefundPolicyComponent} from "./pages/refund-policy/refund-policy.component";
import {CancellationPolicyComponent} from "./pages/cancellation-policy/cancellation-policy.component";

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'report/:report-type',
    component: ReportComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionComponent
  },
  {
    path: 'refund-policy',
    component: RefundPolicyComponent
  },
  {
    path: 'cancellation-policy',
    component: CancellationPolicyComponent
  },
  {
    path: '**', // Wildcard route for a 404 page (optional)
    redirectTo: '', // Redirect to home for unknown routes (or replace with a 404 component)
  }
];
