<div class="s-container mb-10">
  <div class="px-4">
    <div class="font-poppins text-[42px] font-semibold leading-[54.6px] text-left text-[#101010] mb-6">
      Here's Why We Are Different:
    </div>
    <div class="font-poppins text-[18px] font-normal leading-[27px] text-left text-[#484848] mb-6">
      Our reports are unlike anything you would have read online or offline!
    </div>
  </div>

  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:px-12">
    <div *ngFor="let card of cardData" class="bg-white p-6 rounded-lg w-[370px] h-[388px] mb-4 shadow-[20px_35px_70px_0px_rgba(0,0,0,0.04)]">
      <div *ngIf="card.icon" class="mb-4 bg-white h-[72px] w-[72px] flex items-center bg-gradient-alternate shadow-[0px_17px_64px_0px_rgba(0,0,0,0.05)]">
        <img [src]="card.icon" alt="icon" class="mx-auto w-12 h-12">
      </div>
      <h2 class="text-xl font-semibold mb-4">{{ card.title }}</h2>
      <p class="text-gray-600">{{ card.description }}</p>
    </div>
  </div>
</div>
