import { Component } from '@angular/core';
import {HeaderComponent} from "../../common/component/header/header.component";
import {CommonModule} from "@angular/common";
import {FooterComponent} from "../../common/component/footer/footer.component";

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [HeaderComponent, CommonModule, FooterComponent],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
  policySections = [
    {
      title: "1. Identify the Site or App Owner",
      description: `The owner of this site and app is AIQuiver Vision LLP. The site and app are collectively referred to as <strong>Staarvani</strong> and are accessible via <a href="https://staarvani.com/" target="_blank" class="text-blue-900 underline">Staarvani.com</a>`,
    },
    {
      title: "2. Effective Date of the Policy",
      description: "This Privacy Policy is effective as of October 1, 2024. It will remain in effect until October 1, 2035, unless otherwise updated or amended.",
    },
    {
      title: "3. Customer Data Collected",
      description: "We collect the following types of data from our users:",
      items: [
        {
          title: "Personal Information:",
          description: "This may include your name, email address, phone number, date of birth, and any other information you provide when using our services.",
        },
        {
          title: "Numerology Data:",
          description: "Data related to your numerology reports, including birth dates, names, and other numerological data you may provide.",
        },
        {
          title: "Usage Data:",
          description: "Information about your interactions with our site and app, including IP addresses, browser types, and usage patterns.",
        },
      ],
    },
    {
      title: "4. Data Storage and Protection",
      description: "We collect the following types of data from our users:",
      items: [
        {
          title: "Storage:",
          description: "Your data is stored securely on our servers and is protected by encryption and other security measures.",
        },
        {
          title: "Protection:",
          description: "We implement industry-standard security practices to safeguard your data from unauthorized access, alteration, or disclosure. This includes physical, electronic, and procedural safeguards.",
        }
      ],
    },
    {
      title: "5. How the Data Is Used",
      description: "We use your data for the following purposes:",
      items: [
        {
          title: "Service Delivery:",
          description: "To provide you with numerology reports and other services as requested.",
        },
        {
          title: "Customer Support:",
          description: "To respond to your inquiries, provide customer service, and resolve issues.",
        },
        {
          title: "Improvement of Services:",
          description: "To analyze usage patterns and improve our offerings.",
        },
        {
          title: "Communication:",
          description: "To send you updates, newsletters, and other relevant information, provided you have consented to receive such communications.",
        },
      ],
    },
    {
      title: "6. Tracking Tools",
      description: "We use the following tracking tools to enhance your experience:",
      items: [
        {
          title: "Cookies:",
          description: "To remember your preferences and improve site functionality.",
        },
        {
          title: "Analytics:",
          description: "Tools such as Google Analytics to monitor and analyze site traffic and user behavior.",
        }
      ],
    },
    {
      title: "7. Third-Party Access",
      description: "We may share your data with third-party service providers who assist us in operating our site, conducting business, or servicing you. " +
        "These third parties are obligated to protect your data and use it only for the purposes we specify. We do not sell or rent your personal data to third parties."
    },
    {
      title: "8. Opt-Out Clause",
      description: "You may opt out of receiving marketing communications from us at any time " +
        "by following the unsubscribe instructions included in each email or by contacting us directly. " +
        "You can also control the use of cookies through your browser settings."
    },
    {
      title: "9. Description of Process for Changes and Updates to the Policy",
      description: "We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. When we make significant changes, " +
        "we will notify you by posting the updated policy on our site and app with a revised effective date. It is your responsibility to review this policy " +
        "regularly to stay informed of any updates. Your continued use of our services after changes are made constitutes your acceptance of the updated policy."
    },
    {
      title: "Disclaimer",
      description: "The numerology reports provided by Staarvani are based on the information you provide and our interpretation of numerological principles. " +
        "While we strive to offer insightful and accurate reports, we cannot guarantee the completeness or accuracy of the information. The reports are intended for general informational and guidance purposes only and should not be considered as definitive or absolute. " +
        "Users are encouraged to use the information provided as a supplementary tool and not as a substitute for professional advice or judgment."
    },
    {
      title: "Contact Us",
      description: "If you have any questions about this Privacy Policy or our data practices, please contact us at:",
      subItems: [
        {
          address: `<a href="mailto:support@staarvani.com" class="text-blue-900 underline">support@staarvani.com</a>`,
          iconUrl: 'assets/images/privacy-policy/privacy-policy-email.png'
        },
        {
          address: '48B Sports Villa, Jaypee Greens, Greater Noida 201310',
          iconUrl: 'assets/images/privacy-policy/privacy-policy-location.png'
        }
      ]
    },
  ];
}
