import { Component } from '@angular/core';

@Component({
  selector: 'app-why-we-are-different-kid-astro-report-section',
  standalone: true,
  imports: [],
  templateUrl: './why-we-are-different-kid-astro-report-section.component.html',
  styleUrl: './why-we-are-different-kid-astro-report-section.component.scss'
})
export class WhyWeAreDifferentKidAstroReportSectionComponent {

}
