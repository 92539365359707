<footer class="bg-white py-4">
    <div class="container mx-auto">
      <div>
        <div class="flex justify-center">
          <div class="font-poppins text-sm md:text-base text-[#828282] font-light leading-6">
            Copyright © Staarvani 2024. All Rights Reserved. | Privacy Policy 
          </div>
        </div>
      </div>
    </div>
</footer>
  