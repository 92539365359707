import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-get-your-name-correction-report',
  standalone: true,
    imports: [
        CommonModule
    ],
  templateUrl: './get-your-name-correction-report.component.html',
  styleUrl: './get-your-name-correction-report.component.scss'
})
export class GetYourNameCorrectionReportComponent {

  nameCorrectionData = [
    {
      icon: 'assets/images/name-correction-report/personalized-name-analysis.png',
      title: 'Personalized Name Analysis',
      description: 'Understand the energy and vibration behind your current name and its impact on your life.',
      color: 'red-600'
    },
    {
      icon: 'assets/images/name-correction-report/name-correction-suggestions.png',
      title: 'Name Correction Suggestions',
      description: 'Receive numerology-based name modifications that can help align your life with positive outcomes.',
      color: 'red-600'
    },
    {
      icon: 'assets/images/name-correction-report/professional-and-personal-benefits.png',
      title: 'Professional and Personal Benefits',
      description: 'Learn how name correction can boost career success, relationships, and personal growth.',
      color: 'orange-500'
    },
    {
      icon: 'assets/images/name-correction-report/health-and-wellness.png',
      title: 'Health and Wellness',
      description: 'See how your name’s vibration can influence your overall physical and emotional health.',
      color: 'red-600'
    }
  ];
}
