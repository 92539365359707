import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class CommunicatorService {
  public httpOptions: any;

  constructor(private http: HttpClient, private errorService: ErrorHandlerService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        Accept: 'application/json',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE',
        'Access-Control-Allow-Origin': '*',
        responseType: 'arraybuffer',
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept',
      }),
    };
  }

  public getData(url: string, httpOptions?: any): Observable<any> {
    let options;
    if (httpOptions) {
      options = httpOptions;
    } else {
      options = this.httpOptions;
    }
    return this.http.get(url, options).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public postData(url: string, data?: any, httpOptions?: any): Observable<any> {
    let options;
    if (httpOptions) {
      options = httpOptions;
    } else {
      options = this.httpOptions;
    }
    return this.http.post(url, data, options).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public patchData(url: string, data?: any, httpOptions?: any): Observable<any> {
    let options;
    if (httpOptions) {
      options = httpOptions;
    } else {
      options = this.httpOptions;
    }
    return this.http.patch(url, data, options).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
